import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Center,
  chakra,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Facebook, Instagram, Play, Ticket, Youtube } from "iconsax-react";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetMovie } from "../../adaptors";
import Head from "../../components/Head";
import BackgroundImageCarousel from "./components/BackgroundImageCarousel";
import BookOrderModal from "./components/BookOrderModal";
import Carousel from "./components/Carousel";
import ConfirmOrderModal from "./components/ConfirmOrderModal";
import WatchMovieModal from "./components/WatchMovieModal";

const date = new Date();

const Home = () => {
  const { movieId } = useParams();
  const {
    isOpen: isTrailerModalOpen,
    onOpen: onOpenTrailerModal,
    onClose: onCloseTrailerModal,
  } = useDisclosure();
  const {
    isOpen: isBookOrderModalOpen,
    onOpen: onOpenBookOrderModal,
    onClose: onCloseBookOrderModal,
  } = useDisclosure();
  const {
    isOpen: isConfirmOrderModalOpen,
    onOpen: onOpenConfirmOrderModal,
    onClose: onCloseConfirmOrderModal,
  } = useDisclosure();
  const {
    isOpen: isWatchMovieModalOpen,
    onOpen: onOpenWatchMovieModal,
    onClose: onCloseWatchMovieModal,
  } = useDisclosure();
  const { movieData, premiereDates: fetchedPremiereDates } = useGetMovie(
    movieId ? movieId : ""
  );

  const premiereDates =
    fetchedPremiereDates &&
    fetchedPremiereDates.length > 0 &&
    fetchedPremiereDates.filter(({ date }) => {
      const isDatePassed = moment().isAfter(moment.unix(date.seconds));
      return !isDatePassed;
    });

  return (
    <>
      <Head title={movieData?.title} description={movieData?.plot} />
      <Box backgroundColor="gray.900">
        <Box position="absolute" width="full" height="full">
          {!!movieData && (
            <BackgroundImageCarousel
              images={
                movieData.backgroundEditedPosterUrls ||
                movieData.backgroundPosterUrls
              }
              fixed
            />
          )}
          <Box
            position="absolute"
            left={0}
            height="full"
            width="full"
            maxWidth="container.lg"
            bgGradient="linear(to-r,#181818 10%,rgba(23,23,23,.98) 20%,rgba(23,23,23,.97) 25%,rgba(23,23,23,.95) 35%,rgba(23,23,23,.94) 40%,rgba(23,23,23,.92) 45%,rgba(23,23,23,.9) 50%,rgba(23,23,23,.87) 55%,rgba(23,23,23,.82) 60%,rgba(23,23,23,.75) 65%,rgba(23,23,23,.63) 70%,rgba(23,23,23,.45) 75%,rgba(23,23,23,.27) 80%,rgba(23,23,23,.15) 85%,rgba(23,23,23,.08) 90%,rgba(23,23,23,.03) 95%,rgba(23,23,23,0) 100%)"
          ></Box>
        </Box>
        <VStack
          spacing={10}
          align="stretch"
          minHeight="100vh"
          position="relative"
        >
          <Box>
            <Container maxWidth="container.xl">
              <Box py="5" pt="10">
                <Wrap spacing="5" justify={["center", "space-between"]}>
                  <WrapItem>
                    <Box>
                      <Image
                        maxWidth="48"
                        src="/images/fruitful-studios-logo.png"
                        alt="Fruitful Studios Logo"
                      />
                    </Box>
                  </WrapItem>
                  <WrapItem>
                    <HStack justifyContent="end" space="2">
                      <Link
                        href="https://facebook.com/fruitfulstudios"
                        isExternal
                      >
                        <IconButton
                          colorScheme="facebook"
                          aria-label="Facebook"
                          borderRadius="full"
                          icon={<Facebook color="white" variant="Bold" />}
                        />
                      </Link>
                      <Link
                        href="https://instagram.com/fruitfulstudios"
                        isExternal
                      >
                        <IconButton
                          colorScheme="pink"
                          aria-label="Instagram"
                          borderRadius="full"
                          icon={<Instagram color="white" variant="Bold" />}
                        />
                      </Link>
                      <Link
                        href="https://youtube.com/channel/UCG6MyiaTJdPRO1oSImbHa_g"
                        isExternal
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Youtube"
                          borderRadius="full"
                          icon={<Youtube color="white" variant="Bold" />}
                        />
                      </Link>
                    </HStack>
                  </WrapItem>
                </Wrap>
              </Box>
            </Container>
          </Box>
          <Flex grow={1} direction="column" justifyContent="center">
            <Container maxWidth="container.xl">
              {!movieData && (
                <Center>
                  <Spinner size="xl" color="white" />
                </Center>
              )}
              {!!movieData && (
                <Box maxWidth="container.sm">
                  <VStack
                    spacing="5"
                    alignItems={["center", "start"]}
                    textAlign={["center", "start"]}
                  >
                    <Box maxWidth="md">
                      <Image
                        src={movieData.lightLogoUrl}
                        alt={`${movieData.title} Logo`}
                      />
                    </Box>
                    <Box
                      width="full"
                      height="40"
                      rounded="md"
                      overflow="hidden"
                      position="relative"
                      display={["block", "none"]}
                    >
                      {!!movieData && (
                        <BackgroundImageCarousel
                          images={movieData.backgroundPosterUrls}
                        />
                      )}
                    </Box>
                    <Breadcrumb
                      spacing="2"
                      separator={<Text color="gray.200">|</Text>}
                    >
                      <BreadcrumbItem>
                        <Text color="gray.400" fontWeight="bold">
                          {movieData.year}
                        </Text>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Text color="gray.400" fontWeight="bold">
                          {movieData.rating}
                        </Text>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Text color="gray.400" fontWeight="bold">
                          {movieData.genre}
                        </Text>
                      </BreadcrumbItem>
                    </Breadcrumb>
                    <Text color="white">{movieData.plot}</Text>

                    <HStack
                      spacing={0}
                      flexWrap="wrap"
                      justifyContent={["center", "start"]}
                      color="gray.400"
                    >
                      <Text
                        fontWeight="bold"
                        mr="8px !important"
                      >{`Starring: `}</Text>
                      {movieData.stars.split(",").map((star, index) => (
                        <Text key={index} mr="8px !important">
                          {star}
                          {`${
                            movieData.stars.split(",").length - 1 === index
                              ? ""
                              : ", "
                          }`}
                        </Text>
                      ))}
                    </HStack>
                    <Box alignSelf={["stretch", "unset"]}>
                      <Stack
                        spacing="2.5"
                        direction={["column", "row"]}
                        alignItems={["stretch", "unset"]}
                        justify={["center", "start"]}
                      >
                        {!!premiereDates && premiereDates.length > 0 && (
                          <Button
                            colorScheme="green"
                            variant="solid"
                            leftIcon={<Ticket color="white" variant="Bold" />}
                            onClick={onOpenBookOrderModal}
                          >
                            Book order
                          </Button>
                        )}
                        <Button
                          colorScheme="whiteAlpha"
                          variant="outline"
                          leftIcon={<Play color="gray" variant="Bold" />}
                          onClick={onOpenTrailerModal}
                        >
                          Watch trailer
                        </Button>
                        {movieData.isStreamingAllowed && (
                          <Button
                            colorScheme="green"
                            variant="solid"
                            leftIcon={<Play color="white" variant="Bulk" />}
                            onClick={onOpenWatchMovieModal}
                          >
                            Watch movie
                          </Button>
                        )}
                      </Stack>
                    </Box>
                    {!!premiereDates && premiereDates.length > 0 && (
                      <Box alignSelf={["stretch", "unset"]}>
                        <Wrap
                          justify={["center", "flex-start"]}
                          shouldWrapChildren
                          pt={6}
                        >
                          <Text color="white" align={"center"}>
                            {`Already bought a order? `}
                          </Text>
                          <Button
                            colorScheme="green"
                            variant="link"
                            onClick={onOpenConfirmOrderModal}
                          >
                            {`Click here to confirm`}
                          </Button>
                        </Wrap>
                      </Box>
                    )}
                    {!!movieData && (
                      <VStack
                        color="white"
                        align={["center", "start"]}
                        justify={["center", "start"]}
                        textAlign={["center", "start"]}
                        py={5}
                      >
                        <Text fontSize="lg" fontWeight="bold">
                          Featured Reviews
                        </Text>
                        <Box>
                          <Carousel
                            values={movieData.featuredReviews}
                            transition={500}
                            interval={20000}
                            renderValue={(value) => (
                              <Text fontSize="sm" fontStyle="italic">
                                <chakra.span fontWeight="bold">{`${value.name}: `}</chakra.span>
                                {value.review}
                              </Text>
                            )}
                          />
                        </Box>
                      </VStack>
                    )}
                  </VStack>
                </Box>
              )}
            </Container>
          </Flex>
          <Box>
            <Container maxWidth="container.xl">
              <Box py="5">
                <Text color="gray.200" textAlign="center">
                  © {date.getFullYear()} Fruitful Studios. All rights reserved.
                </Text>
              </Box>
            </Container>
          </Box>
        </VStack>
      </Box>

      {!!movieData && !!premiereDates && premiereDates.length > 0 && (
        <BookOrderModal
          isOpen={isBookOrderModalOpen}
          onClose={onCloseBookOrderModal}
          movieData={movieData}
          premiereDates={premiereDates}
        />
      )}

      {!!movieData && movieData.isStreamingAllowed && (
        <WatchMovieModal
          isOpen={isWatchMovieModalOpen}
          onClose={onCloseWatchMovieModal}
          movieData={movieData}
        />
      )}

      {!!movieData && (
        <Modal isOpen={isTrailerModalOpen} onClose={onCloseTrailerModal}>
          <ModalOverlay />
          <ModalContent
            maxWidth="4xl"
            rounded={[0, "md"]}
            containerProps={{
              p: ["0", "5"],
            }}
            height={["full", "auto"]}
            marginTop={["0", "3.75rem"]}
            marginBottom={["0", "3.75rem"]}
          >
            <ModalHeader>Trailer</ModalHeader>
            <ModalCloseButton />
            <ModalBody pt="0" pb="0" pl="0" pr="0" p="2">
              <AspectRatio ratio={16 / 9} position="relative">
                <iframe
                  title={`${movieData.title} trailer`}
                  src={movieData.trailerVideoUrl}
                  allowFullScreen
                  style={{
                    borderRadius: 5,
                  }}
                />
              </AspectRatio>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {!!movieData && (
        <ConfirmOrderModal
          isOpen={isConfirmOrderModalOpen}
          onClose={onCloseConfirmOrderModal}
          movieId={movieData.id}
        />
      )}
    </>
  );
};

export default Home;

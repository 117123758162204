import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ConfirmBookingInput,
  ConfirmBookingOutput,
  confirmBookingService,
} from "../services/firebase";

export const useConfirmBooking = () => {
  const toast = useToast();
  const [isConfirmingBooking, setIsConfirmingBooking] = useState(false);
  const [confirmBookingResultData, setConfirmBookingResultData] =
    useState<ConfirmBookingOutput>();

  const confirmBooking = async (data: ConfirmBookingInput) => {
    try {
      setConfirmBookingResultData(undefined);
      setIsConfirmingBooking(true);
      const result = await confirmBookingService(data);
      setConfirmBookingResultData(result.data);
      setIsConfirmingBooking(false);
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setConfirmBookingResultData(undefined);
      setIsConfirmingBooking(false);
    }
  };

  return {
    isConfirmingBooking,
    confirmBookingResultData,
    confirmBooking,
  };
};

import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ConfirmGetStreamLinkInput,
  ConfirmGetStreamLinkOutput,
  confirmGetStreamLinkService,
} from "../services/firebase";

export const useConfirmGetStreamLink = () => {
  const toast = useToast();
  const [isConfirmingGetStreamLink, setIsConfirmingGetStreamLink] =
    useState(false);
  const [confirmGetStreamLinkResultData, setConfirmGetStreamLinkResultData] =
    useState<ConfirmGetStreamLinkOutput>();

  const confirmGetStreamLink = async (data: ConfirmGetStreamLinkInput) => {
    try {
      setConfirmGetStreamLinkResultData(undefined);
      setIsConfirmingGetStreamLink(true);
      const result = await confirmGetStreamLinkService(data);
      setConfirmGetStreamLinkResultData(result.data);
      setIsConfirmingGetStreamLink(false);
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setConfirmGetStreamLinkResultData(undefined);
      setIsConfirmingGetStreamLink(false);
    }
  };

  return {
    isConfirmingGetStreamLink,
    confirmGetStreamLinkResultData,
    confirmGetStreamLink,
  };
};

import { MovieData } from "../services/firebase";

export const moviePlaceholderData: Partial<MovieData> = {
  darkLogoUrl:
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-logo-black.png?alt=media&token=ec176f81-7ceb-44d3-8f91-8e66ffde28cc",
  trailerVideoUrl: "https://www.youtube.com/embed/qrxNPSAzWXg",
  genre: "Drama, Romance",
  rating: "18+",
  ticketPrice: 3000,
  discount: 500,
  lightLogoUrl:
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-logo.png?alt=media&token=c931efa8-c3d2-4d34-bd72-0853bc611dfd",
  backgroundPosterUrls: [
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-bg01.jpg?alt=media&token=b12fa70a-8d50-4db5-bd4f-79417e041605",
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-bg02.jpg?alt=media&token=7143fe3a-c9f2-4671-bae7-82089a33afb4",
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-bg03.jpg?alt=media&token=062d0f69-f304-47a9-b104-cd268affc45e",
  ],
  stars:
    "Chris Chibuikem, Favour Etim, Imotunde Adeyemo, Maryann Ivy, Ifeanyi Okondu, Amanda Oruh",
  maxSeatsPerTicket: 2,
  year: 2021,
  posterUrl: "",
  videoPosterUrl:
    "https://firebasestorage.googleapis.com/v0/b/fruitfulstudios-27855.appspot.com/o/movies%2Fsomething-special%2Fsomething-special-bg01.jpg?alt=media&token=aff4d84e-92d1-4606-96d7-6231a8bbcf8f",
  title: "Something special",
  plot: "An anthology feature that explores love in the form of special moments in people’s lives and how those moments impact them.",
  isStreamingAllowed: true,
  streamingPrice: 2000,
  maxStreamingDays: 7,
  maxStreamingDevices: 2,
  streamFileId: "x5Vub01lTuu1vBc9xcGg34X776vcWMbei5vy5lY4yofM",
  endCreditsTime: 20,
};

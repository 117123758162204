export enum BookingType {
  Ticket = "ticket",
  Stream = "stream",
}
export interface BookingData {
  movieId: string;
  premiereDate: string;
  seats: number;
  fullname: string;
  ageRange: string;
  email: string;
  type: BookingType;
}

export type InitializeTransactionInput = BookingData;

export interface InitializeTransactionOutput {
  reference: string;
}

export interface ConfirmBookingInput {
  reference: string;
  code: string;
}

export interface ConfirmBookingOutput {
  authorizationUrl: string;
  isAdminOrder: boolean;
}

export interface ResendConfirmationCodeInput {
  reference: string;
}

export interface ResendConfirmationCodeOutput {
  reference: string;
}

export enum Status {
  success = "success",
  failed = "failed",
}

export interface VerifyTransactionInput {
  reference: string;
}

export enum OrderStatus {
  fufilled = "fufilled",
  unavailable = "unavailable",
  duplicate = "duplicate",
}

export interface VerifyTransactionOutput {
  status: Status;
  isAdminOrder: boolean;
  bookingData?: BookingData;
  movieData?: MovieData;
  orderStatus?: OrderStatus;
  orderId?: string;
  confirmationCode?: string;
}

export interface Date {
  seconds: number;
  nanoseconds: number;
}

export interface PremiereDateData {
  id: string;
  movieId: string;
  date: Date;
  venue: string;
  location: string;
  availableSeats: number;
}

export interface FeaturedReviews {
  name: string;
  review: string;
}

export interface MovieData {
  id: string;
  plot: string;
  rating: string;
  genre: string;
  posterUrl: string;
  videoPosterUrl: string;
  stars: string;
  trailerVideoUrl: string;
  backgroundPosterUrls: string[];
  backgroundEditedPosterUrls?: string[];
  ticketPrice: number;
  discount: number;
  title: string;
  maxSeatsPerTicket: number;
  darkLogoUrl: string;
  year: number;
  lightLogoUrl: string;
  isStreamingAllowed: boolean;
  streamingPrice: number;
  maxStreamingDays: number;
  maxStreamingDevices: number;
  streamFileId: string;
  endCreditsTime: number;
  featuredReviews: FeaturedReviews[];
}
export interface OrderData extends BookingData {
  id: string;
  attendance?: number;
}
export interface ChangeTicketDateInput {
  movieId: string;
  orderId: string;
  premiereDate: string;
}
export interface ChangeTicketDateOutput {
  reference: string;
}

export interface ConfirmChangeTicketDateInput {
  reference: string;
  code: string;
}

export interface ConfirmChangeTicketDateOutput {
  reference: string;
}
export interface GetStreamLinkInput {
  movieId: string;
  orderId: string;
}
export interface GetStreamLinkOutput {
  reference: string;
  hasEmail: boolean;
}

export interface ConfirmGetStreamLinkInput {
  reference: string;
  code: string;
  isOldReference?: boolean;
  orderId: string;
  movieId: string;
}

export interface ConfirmGetStreamLinkOutput {
  streamLink: string;
  posterLink: string;
  reference: string;
  confirmationRequired: boolean;
}

export interface SendReminderEmailsInput {
  premiereDate: string;
}

export interface SendReminderEmailsOutput {
  emails: Array<string>;
}

export interface AddReviewInput {
  content: string;
  orderId: string;
  movieId: string;
}

export interface AddReviewOutput {
  id: string;
}

export interface SendInvitationInput {
  emails: string[];
  orderId: string;
  movieId: string;
}

export interface SendInvitationOutput {
  id: string;
}

export interface AdminLoginInput {
  email: string;
}

export type AdminLoginOutput = void;

import {
  Box,
  Button,
  chakra,
  Checkbox,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CloseCircle, TickCircle } from "iconsax-react";
import moment from "moment";
import React, { useState } from "react";
import { useMarkAttendance } from "../../../../adaptors";
import {
  OrderData,
  PremiereDateData,
} from "../../../../services/firebase-types";

interface Props {
  orderData: OrderData;
  premiereDate: PremiereDateData;
  onClose: () => void;
}

const OrderDetails: React.FC<Props> = ({
  orderData,
  premiereDate,
  onClose,
}) => {
  const { date } = premiereDate;
  const [isSingleAttendance, setIsSingleAttendance] = useState(false);
  const {
    markAttendance,
    isMarkingAttendance,
    unmarkAttendance,
    isUnmarkingAttendance,
  } = useMarkAttendance();

  const markAttendanceHandler = async () => {
    let attendance = orderData.seats;
    if (orderData.seats > 1 && isSingleAttendance) {
      attendance = 1;
    }
    await markAttendance(orderData.id, attendance);
    onClose();
  };

  const unmarkAttendanceHandler = async () => {
    if (orderData.attendance) {
      let attendance = orderData.attendance - orderData.seats;
      if (orderData.seats === 2 && isSingleAttendance) {
        attendance = orderData.attendance - 1;
      }
      if (orderData.seats === 2 && orderData.attendance === 1) {
        attendance = orderData.attendance - 1;
      }
      await unmarkAttendance(orderData.id, attendance);
      onClose();
    }
  };

  const singleAttendaceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSingleAttendance(e.target.checked);
  };

  const renderMarkAttendanceButton = (
    <Button
      colorScheme="green"
      size="sm"
      leftIcon={<TickCircle color="white" size={16} variant="Bold" />}
      isLoading={isMarkingAttendance}
      onClick={markAttendanceHandler}
    >
      Mark attendance
    </Button>
  );

  const renderUnmarkAttendanceButton = (
    <Button
      colorScheme="gray"
      size="sm"
      leftIcon={<CloseCircle size={16} variant="Bold" />}
      isLoading={isUnmarkingAttendance}
      onClick={unmarkAttendanceHandler}
    >
      Unmark attendance
    </Button>
  );

  return (
    <Box
      key={orderData.id}
      borderWidth={1}
      rounded="md"
      px="5"
      py="3"
      position="relative"
    >
      {!!orderData.attendance && (
        <Box position="absolute" top="3" right="3">
          <TickCircle color="green" variant="Bold" />
        </Box>
      )}
      <VStack align="stretch">
        <Box>
          <Text textTransform="uppercase" color="gray.500" fontSize="xs">
            {`TID: ${orderData.id}`}
          </Text>
          <Text textTransform="uppercase" fontSize="sm" fontWeight="semibold">
            {orderData.fullname}
          </Text>
          <Text fontSize="sm">
            <chakra.span fontWeight="semibold">{orderData.seats}</chakra.span>
            {` Seat${orderData.seats > 1 ? "s (Couple)" : " (Single)"}`}
          </Text>
          {!!orderData.attendance && (
            <Text fontSize="sm" color="green" fontWeight="semibold">
              <chakra.span fontWeight="bold">
                {orderData.attendance}
              </chakra.span>
              {` ${orderData.attendance > 1 ? " people" : " person"} attended`}
            </Text>
          )}
          <Text fontSize="sm">
            {moment(moment.unix(date.seconds)).format("LLL")}
          </Text>
        </Box>
        {((orderData.seats > 1 && !orderData.attendance) ||
          (orderData.seats > 1 && orderData.attendance !== 1)) && (
          <HStack>
            <Checkbox onChange={singleAttendaceHandler}>
              <chakra.span fontSize="sm">Only for one person</chakra.span>
            </Checkbox>
          </HStack>
        )}
        <Box alignSelf={["stretch", "unset"]}>
          <Stack
            spacing="2.5"
            direction={["column", "row"]}
            alignItems={["stretch", "unset"]}
            justify={["center", "start"]}
          >
            {orderData.attendance &&
              orderData.attendance < orderData.seats &&
              renderMarkAttendanceButton}
            {!orderData.attendance && renderMarkAttendanceButton}
            {orderData.attendance && renderUnmarkAttendanceButton}
          </Stack>
        </Box>
      </VStack>
    </Box>
  );
};

export default OrderDetails;

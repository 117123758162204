import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useConfirmOrder } from "../../../../adaptors";
import {
  MovieData,
  PremiereDateData,
} from "../../../../services/firebase-types";
import OrderDetails from "./OrderDetails";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  movieData: MovieData;
  premiereDates: PremiereDateData[];
}

interface ConfirmOrderInput {
  identifier: string;
}

const validationSchema = Joi.object<ConfirmOrderInput>({
  identifier: Joi.string().required().min(5),
});

const ConfirmOrderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  movieData,
  premiereDates,
}) => {
  const {
    isOpen: isMarkAttendanceModalOpen,
    onOpen: onOpenMarkAttendanceModal,
    onClose: onCloseMarkAttendanceModal,
  } = useDisclosure();
  const defaultValues: Partial<ConfirmOrderInput> = {
    identifier: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmOrderInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });
  const { confirmOrder, isConfirmingOrder, orderDatas } = useConfirmOrder();

  const confirmOrderHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      const orderDatas = await confirmOrder(input.identifier);
      if (orderDatas && orderDatas.length > 0) {
        onOpenMarkAttendanceModal();
        onCloseHandler();
      }
    });
    onSubmit();
  };

  const confirmOrderFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    confirmOrderHandler();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  const renderOrders =
    movieData &&
    premiereDates &&
    orderDatas?.map((orderData) => {
      let premiereDate = premiereDates.find(
        (value) => value.id === orderData.premiereDate
      );

      if (!premiereDate) {
        return null;
      }

      return (
        <OrderDetails
          orderData={orderData}
          premiereDate={premiereDate}
          onClose={onCloseMarkAttendanceModal}
        />
      );
    });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="md"
          rounded={[0, "md"]}
          containerProps={{
            p: ["0", "5"],
          }}
          height={["auto", "auto"]}
          minHeight={["full", "auto"]}
          marginTop={["0", "3.75rem"]}
          marginBottom={["0", "3.75rem"]}
        >
          <ModalHeader>Confirm order</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
            <form onSubmit={confirmOrderFormHandler}>
              <VStack align="stretch" spacing="5">
                <FormControl isInvalid={!!errors.identifier}>
                  <FormLabel>Enter the email address or order ID</FormLabel>
                  <Input
                    placeholder="Email address or order ID"
                    {...register("identifier")}
                  />
                  <FormErrorMessage>
                    {!!errors.identifier && errors.identifier.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={confirmOrderHandler}
              isLoading={isConfirmingOrder}
            >
              Continue
            </Button>
            <Button onClick={onCloseHandler}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {orderDatas && orderDatas.length > 0 && (
        <Modal
          isOpen={isMarkAttendanceModalOpen}
          onClose={onCloseMarkAttendanceModal}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent
            maxWidth="md"
            rounded={[0, "md"]}
            containerProps={{
              p: ["0", "5"],
            }}
            height={["auto", "auto"]}
            minHeight={["full", "auto"]}
            marginTop={["0", "3.75rem"]}
            marginBottom={["0", "3.75rem"]}
          >
            <ModalHeader />
            <ModalCloseButton />
            <ModalBody borderBottomWidth={1} py="5">
              <VStack spacing="5" align="stretch">
                <Alert status="info" variant="solid" rounded="md">
                  <AlertIcon />
                  Order was found
                </Alert>
                <VStack align="stretch">{renderOrders}</VStack>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onCloseMarkAttendanceModal}>Done</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ConfirmOrderModal;

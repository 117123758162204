import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArrowRight3, Refresh2 } from "iconsax-react";
import React, { useCallback, useEffect } from "react";
import { useVerifyTransaction } from "../../adaptors";
import Head from "../../components/Head";
import {
  BookingData,
  BookingType,
  OrderStatus,
  Status,
} from "../../services/firebase";
import { getQueryParam } from "../../utils/helpers";

const VerifyTransaction = () => {
  const reference = getQueryParam("reference");
  const {
    isVerifyingTransaction,
    isVerifyTransactionError,
    verifyTransaction,
    verifyTransactionResultData,
  } = useVerifyTransaction();

  const verifyTransactionHandler = useCallback(() => {
    if (reference) {
      verifyTransaction({
        reference,
      });
    }
  }, [reference, verifyTransaction]);

  useEffect(() => {
    verifyTransactionHandler();
  }, [verifyTransactionHandler]);

  let bookingData: BookingData | undefined;
  if (
    verifyTransactionResultData &&
    verifyTransactionResultData.status === Status.success
  ) {
    bookingData = verifyTransactionResultData.bookingData;
  }

  if (!reference) {
    return null;
  }

  return (
    <Box width="100%" h="100vh">
      <Head />
      <Modal
        isOpen={true}
        onClose={() => null}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
        size="full"
      >
        <ModalContent boxShadow="none" maxWidth="xl">
          <ModalBody borderBottomWidth={1} py="5">
            <HStack mt="5" mb="10">
              <Link href="/">
                <Image
                  maxWidth="48"
                  src="/images/fruitful-studios-logo-green.png"
                  alt="Fruitful Studios Logo"
                />
              </Link>
            </HStack>
            <VStack spacing="5" align="stretch">
              {isVerifyingTransaction && (
                <Alert status="info" variant="left-accent" rounded="md">
                  <Box>
                    <Spinner size="sm" mr="3" />
                  </Box>
                  {`Please wait while we verify your payment.`}
                </Alert>
              )}
              {isVerifyTransactionError && (
                <Alert status="error" variant="left-accent" rounded="md">
                  <AlertIcon />
                  {`An error occured while attempting to verify transaction.`}
                </Alert>
              )}
              {isVerifyTransactionError && (
                <Button
                  alignSelf="center"
                  leftIcon={<Refresh2 size="20" color="white" />}
                  colorScheme="blackAlpha"
                  onClick={verifyTransactionHandler}
                >
                  Retry
                </Button>
              )}
              {verifyTransactionResultData &&
                verifyTransactionResultData.status === Status.failed && (
                  <Alert status="error" variant="left-accent" rounded="md">
                    <AlertIcon />
                    {`Your payment was not successful. If this is an error on our
                    part, please reach out to us for quick resolution.`}
                  </Alert>
                )}

              {verifyTransactionResultData &&
                verifyTransactionResultData.status === Status.success &&
                bookingData && (
                  <>
                    {bookingData.type === BookingType.Stream && (
                      <>
                        <Alert
                          status="success"
                          variant="left-accent"
                          rounded="md"
                        >
                          <AlertIcon />
                          <Text>
                            {verifyTransactionResultData.isAdminOrder ? (
                              "The order was successfully fulfilled."
                            ) : (
                              <>
                                {`Your payment was successful. A personal link to
                                watch `}
                                <chakra.span fontWeight="bold">
                                  {`${verifyTransactionResultData.movieData?.title}`}
                                </chakra.span>
                                {` was sent to your email address.`}
                              </>
                            )}
                          </Text>
                        </Alert>
                        <Alert status="info" variant="left-accent" rounded="md">
                          <AlertIcon />
                          {`${
                            verifyTransactionResultData.isAdminOrder
                              ? `Send the link below with the streaming code (${verifyTransactionResultData.confirmationCode}) to the user.`
                              : "You may also watch now by following the link below."
                          }`}
                        </Alert>

                        <Button
                          colorScheme="green"
                          variant="outline"
                          as={Link}
                          href={`/watch/${bookingData.movieId}/${verifyTransactionResultData.orderId}`}
                          rightIcon={<ArrowRight3 color="green" />}
                        >
                          Watch now
                        </Button>
                      </>
                    )}
                    {bookingData.type === BookingType.Ticket && (
                      <>
                        {verifyTransactionResultData.orderStatus ===
                          OrderStatus.fufilled && (
                          <>
                            <Alert
                              status="success"
                              variant="left-accent"
                              rounded="md"
                            >
                              <AlertIcon />
                              {`${
                                verifyTransactionResultData.isAdminOrder
                                  ? "Order was successfully created."
                                  : "Your payment was successful. Order has been sent to your email address."
                              }`}
                            </Alert>
                            <Link
                              href={`/orders/${bookingData.movieId}/${verifyTransactionResultData.orderId}`}
                              _hover={{
                                textDecoration: "none",
                                opacity: 0.9,
                              }}
                            >
                              <Alert
                                status="info"
                                variant="left-accent"
                                rounded="md"
                              >
                                <AlertIcon />
                                {`You can follow this link to view the order.`}
                              </Alert>
                            </Link>
                          </>
                        )}

                        {verifyTransactionResultData.orderStatus ===
                          OrderStatus.unavailable && (
                          <Alert
                            status="warning"
                            variant="left-accent"
                            rounded="md"
                          >
                            <AlertIcon />
                            {`Your order was not created because one or more seats booked is currently unavailable. Please contact us to request refund or get orders to available seat(s).`}
                          </Alert>
                        )}

                        {verifyTransactionResultData.orderStatus ===
                          OrderStatus.duplicate && (
                          <Alert
                            status="warning"
                            variant="left-accent"
                            rounded="md"
                          >
                            <AlertIcon />
                            {`Your order was not created because you have already bought a order for the selected date. Please contact us to request refund.`}
                          </Alert>
                        )}
                      </>
                    )}
                  </>
                )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VerifyTransaction;

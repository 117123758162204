import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import { Logout } from "iconsax-react";
import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AdminAuthContext from "../context/AdminAuth";

const AdminLayout: React.FC = ({ children }) => {
  const { user, logOut } = useContext(AdminAuthContext);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  const logOutHandler = () => {
    logOut();
  };

  return (
    <Box w="full" margin="0 auto" p="5" maxWidth="xl" boxShadow="none">
      <Box mt="5" mb="10">
        <Box alignSelf={["stretch", "unset"]}>
          <Stack
            spacing="5"
            direction={["column", "row"]}
            alignItems={["stretch", "unset"]}
            justify={["center", "space-between"]}
          >
            <Link href="/admin">
              <Image
                maxWidth="48"
                src="/images/fruitful-studios-logo-green.png"
                alt="Fruitful Studios Logo"
              />
            </Link>
            <Button
              colorScheme="gray"
              leftIcon={<Logout size={20} />}
              onClick={logOutHandler}
            >
              Sign out
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box mb="10">
        <Alert status="info" variant="solid" rounded="md">
          <AlertIcon />
          {`Welcome to the admin panel.`}
        </Alert>
      </Box>
      {children}
    </Box>
  );
};

export default AdminLayout;

import { useToast } from "@chakra-ui/react";
import Joi from "joi";
import { useState } from "react";
import {
  doc,
  getDoc,
  getDocs,
  OrderData,
  ordersCollection,
  query,
  where,
} from "../services/firebase";

export const useConfirmOrder = () => {
  const toast = useToast();
  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);
  const [orderDatas, setOrderDatas] = useState<OrderData[]>();

  const confirmOrder = async (identifier: string) => {
    try {
      setIsConfirmingOrder(true);

      let orderData: OrderData[] | undefined = [];
      const emailValidation = Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .validate(identifier);
      if (emailValidation.error) {
        const docSnap = await getDoc(
          doc(ordersCollection, identifier.toLowerCase())
        );
        if (docSnap.exists()) {
          const data = { ...docSnap.data(), id: docSnap.id } as OrderData;
          orderData = [data];
        }
      } else {
        const querySnap = await getDocs(
          query(
            ordersCollection,
            where("email", "==", identifier.toLowerCase())
          )
        );
        if (querySnap.size > 0) {
          const datas = querySnap.docs.map((doc) => {
            const data = {
              ...doc.data(),
              id: doc.id,
            } as OrderData;
            return data;
          });
          orderData = datas;
        }
      }
      setIsConfirmingOrder(false);
      if (orderData.length < 1) {
        throw new Error("No order was found.");
      }
      setOrderDatas(orderData);
      return orderData;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setIsConfirmingOrder(false);
    }
  };

  return {
    isConfirmingOrder,
    orderDatas,
    confirmOrder,
  };
};

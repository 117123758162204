import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowCircleRight2 } from "iconsax-react";
import Joi from "joi";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useInitializeTransaction } from "../../../adaptors";
import {
  ageRanges,
  BookingType,
  InitializeTransactionInput,
  MovieData,
} from "../../../services/firebase";
import ConfirmBookingModal from "./ConfirmBookingModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  movieData: MovieData;
}

const WatchMovieModal: React.FC<Props> = ({ isOpen, onClose, movieData }) => {
  const {
    isOpen: isConfirmBookingModalOpen,
    onOpen: onOpenConfirmBookingModal,
    onClose: onCloseConfirmBookingModal,
  } = useDisclosure();
  const [bookingReference, setBookingReference] = useState<string>();
  const defaultValues: Partial<InitializeTransactionInput> = {};

  const validationSchema = Joi.object<InitializeTransactionInput>({
    fullname: Joi.string().required().trim().min(3),
    ageRange: Joi.string()
      .required()
      .valid(...ageRanges)
      .messages({ "any.only": "is not a valid age range" }),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<InitializeTransactionInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });
  const { initializeTransaction, isInitializingTransaction } =
    useInitializeTransaction();

  const initializeTransactionHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      const data = await initializeTransaction({
        ...input,
        movieId: movieData.id,
        type: BookingType.Stream,
      });
      if (data) {
        setBookingReference(data.reference);
        onOpenConfirmBookingModal();
        onCloseHandler();
      }
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  const renderAgeRangeOptions = ageRanges.map((ageRange) => (
    <option
      key={ageRange}
      value={ageRange}
      style={{
        color: "#1A202C",
      }}
    >{`${ageRange} years old`}</option>
  ));

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="md"
          rounded={[0, "md"]}
          containerProps={{
            p: ["0", "5"],
          }}
          height={["auto", "auto"]}
          minHeight={["full", "auto"]}
          marginTop={["0", "3.75rem"]}
          marginBottom={["0", "3.75rem"]}
        >
          <ModalHeader>Watch movie</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
            <form onSubmit={initializeTransactionHandler}>
              <VStack align="stretch" spacing="5">
                <FormControl isInvalid={!!errors.fullname}>
                  <Input placeholder="Fullname" {...register("fullname")} />
                  <FormErrorMessage>
                    {!!errors.fullname && errors.fullname.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.ageRange}>
                  <Select
                    placeholder="Age range"
                    {...register("ageRange")}
                    color={watch().ageRange ? undefined : "gray.400"}
                  >
                    {renderAgeRangeOptions}
                  </Select>
                  <FormErrorMessage>
                    {!!errors.ageRange && errors.ageRange.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.email}>
                  <Input
                    type="email"
                    placeholder="Email address"
                    {...register("email")}
                  />
                  <FormErrorMessage>
                    {!!errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <Text>
                    <chakra.span>{`Total cost: `}</chakra.span>
                    <chakra.span fontWeight="bold">
                      {movieData.streamingPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </chakra.span>
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    <chakra.span>
                      {`After payment, you will receive a personal link to watch `}
                    </chakra.span>
                    <chakra.span fontWeight="semibold">
                      {movieData.title}
                    </chakra.span>
                    <chakra.span>
                      {` for ${
                        movieData.maxStreamingDays === 7
                          ? "a week"
                          : `${movieData.maxStreamingDays} days`
                      }.`}
                    </chakra.span>
                  </Text>
                </Box>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              rightIcon={<ArrowCircleRight2 color="white" variant="Bold" />}
              isLoading={isInitializingTransaction}
              onClick={initializeTransactionHandler}
            >
              Continue
            </Button>
            <Button onClick={onCloseHandler}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {bookingReference && (
        <ConfirmBookingModal
          isOpen={isConfirmBookingModalOpen}
          onClose={onCloseConfirmBookingModal}
          reference={bookingReference}
        />
      )}
    </>
  );
};

export default WatchMovieModal;

import { Helmet } from "react-helmet";

interface HeadProps {
  title?: string;
  description?: string;
}

const Head: React.FC<HeadProps> = ({
  title,
  description = "Fruitful Studios is a Film, Video and Photography company with the aim of producing fruitful visual and audio contents.",
}) => {
  return (
    <Helmet>
      <title>{title ? `${title} | ` : ""}Fruitful Studios</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Head;

import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ResendConfirmationCodeInput,
  ResendConfirmationCodeOutput,
  resendConfirmationCodeService,
} from "../services/firebase";

export const useResendConfirmationCode = () => {
  const toast = useToast();
  const [isResendingConfirmationCode, setIsResendingConfirmationCode] =
    useState(false);
  const [
    resendConfirmationCodeResultData,
    setResendConfirmationCodeResultData,
  ] = useState<ResendConfirmationCodeOutput>();

  const resendConfirmationCode = async (data: ResendConfirmationCodeInput) => {
    try {
      setResendConfirmationCodeResultData(undefined);
      setIsResendingConfirmationCode(true);
      const result = await resendConfirmationCodeService(data);
      setResendConfirmationCodeResultData(result.data);
      setIsResendingConfirmationCode(false);
      const message = "Confirmation code has been resent.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setResendConfirmationCodeResultData(undefined);
      setIsResendingConfirmationCode(false);
    }
  };

  return {
    isResendingConfirmationCode,
    resendConfirmationCodeResultData,
    resendConfirmationCode,
  };
};

import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface Props {
  forceActiveImage?: string;
  cycle?: boolean;
  interval?: number;
  transition?: number;
  images: string[];
  fixed?: boolean;
}

const BackgroundImageCarousel: React.FC<Props> = ({
  forceActiveImage,
  cycle = true,
  interval = 5000,
  transition = 5000,
  images,
  fixed = false,
}) => {
  const [active, setActive] = useState(
    forceActiveImage ? parseInt(forceActiveImage) : 0
  );
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false);

  useEffect(() => {
    if (forceActiveImage) return;
    if (!cycle) return;
    const timeout = setTimeout(() => {
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(transition);
        setActive(active === images.length - 1 ? 0 : active + 1);
        setFirstTransitionIsDone(true);
      }
      if (cycle) {
        startImageTransition();
      }
    }, interval);

    return () => clearTimeout(timeout);
  }, [
    active,
    cycle,
    firstTransitionIsDone,
    forceActiveImage,
    images.length,
    interval,
    transition,
  ]);

  useEffect(() => {
    if (!forceActiveImage) return;
    setActive(parseInt(forceActiveImage));
  }, [active, forceActiveImage]);

  return (
    <>
      {images.map((image, index) => (
        <Box
          key={`${image}-${index}`}
          position="absolute"
          right={0}
          height="full"
          width="full"
          opacity={active === index ? 1 : 0}
          transition={`opacity ${transition}ms`}
          backgroundImage={`url('${image}')`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center right"
          backgroundSize="cover"
          backgroundAttachment={fixed ? "fixed" : "unset"}
        />
      ))}
    </>
  );
};

export default BackgroundImageCarousel;

import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { auth, User, onAuthStateChanged, signOut } from "../services/firebase";

interface IAdminAuthContext {
  user: User | null;
  logOut: () => Promise<void>;
}

export const AdminAuthContext = createContext<IAdminAuthContext>(
  {} as IAdminAuthContext
);

export const AdminAuthContextProvider: React.FC = () => {
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoadingUser(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch {}
  };

  return (
    <AdminAuthContext.Provider
      value={{
        user,
        logOut,
      }}
    >
      <Box minHeight="100vh" backgroundColor="gray.50">
        {isLoadingUser && (
          <Flex height="100vh" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        )}
        {!isLoadingUser && <Outlet />}
      </Box>
    </AdminAuthContext.Provider>
  );
};

export default AdminAuthContext;

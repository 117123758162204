import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import {
  VerifyTransactionInput,
  VerifyTransactionOutput,
  verifyTransactionService,
} from "../services/firebase";

export const useVerifyTransaction = () => {
  const toast = useToast();
  const [isVerifyingTransaction, setIsVerifyingTransaction] = useState(false);
  const [verifyTransactionResultData, setVerifyTransactionResultData] =
    useState<VerifyTransactionOutput>();
  const [isVerifyTransactionError, setIsVerifyTransactionError] =
    useState(false);

  const verifyTransaction = useCallback(
    async (data: VerifyTransactionInput) => {
      try {
        setVerifyTransactionResultData(undefined);
        setIsVerifyingTransaction(true);
        setIsVerifyTransactionError(false);
        const result = await verifyTransactionService(data);
        setVerifyTransactionResultData(result.data);
        setIsVerifyingTransaction(false);
        return result.data;
      } catch (error) {
        const errorData = error as Error;
        const errorId = `error-${errorData.message}`;
        if (!toast.isActive(errorId)) {
          toast({
            id: errorId,
            description: errorData.message,
            status: "error",
            variant: "left-accent",
            position: "top",
          });
        }
        setVerifyTransactionResultData(undefined);
        setIsVerifyingTransaction(false);
        setIsVerifyTransactionError(true);
      }
    },
    [toast]
  );

  return {
    isVerifyingTransaction,
    isVerifyTransactionError,
    verifyTransactionResultData,
    verifyTransaction,
  };
};

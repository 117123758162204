import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  InitializeTransactionInput,
  InitializeTransactionOutput,
  initializeTransactionService,
} from "../services/firebase";

export const useInitializeTransaction = () => {
  const toast = useToast();
  const [isInitializingTransaction, setIsInitializingTransaction] =
    useState(false);
  const [initializeTransactionResultData, setInitializeTransactionResultData] =
    useState<InitializeTransactionOutput>();

  const initializeTransaction = async (data: InitializeTransactionInput) => {
    try {
      setInitializeTransactionResultData(undefined);
      setIsInitializingTransaction(true);
      const result = await initializeTransactionService(data);
      setInitializeTransactionResultData(result.data);
      setIsInitializingTransaction(false);
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setInitializeTransactionResultData(undefined);
      setIsInitializingTransaction(false);
    }
  };

  return {
    isInitializingTransaction,
    initializeTransactionResultData,
    initializeTransaction,
  };
};

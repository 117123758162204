import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { TickCircle } from "iconsax-react";
import Joi from "joi";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useConfirmGetStreamLink } from "../../../adaptors";
import { useResendConfirmationCode } from "../../../adaptors/resend-confirmation-code";
import { ConfirmGetStreamLinkInput } from "../../../services/firebase";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reference: string;
  hasEmail: boolean;
  orderId: string;
  movieId: string;
  onUpdateStreamLink: (link: string, posterLink: string) => void;
}

const validationSchema = Joi.object<ConfirmGetStreamLinkInput>({
  code: Joi.string().required().length(5),
});

const ConfirmGetStreamLinkModal: React.FC<Props> = ({
  isOpen,
  onClose,
  reference,
  hasEmail,
  orderId,
  movieId,
  onUpdateStreamLink,
}) => {
  const defaultValues: Partial<ConfirmGetStreamLinkInput> = {
    code: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmGetStreamLinkInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });
  const { confirmGetStreamLink, isConfirmingGetStreamLink } =
    useConfirmGetStreamLink();
  const { resendConfirmationCode, isResendingConfirmationCode } =
    useResendConfirmationCode();

  const confirmGetStreamLinkHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      const data = await confirmGetStreamLink({
        ...input,
        reference,
        orderId,
        movieId,
      });
      if (data && !data.confirmationRequired) {
        window.localStorage.setItem(
          `last_confirmation_reference_${orderId}`,
          data.reference
        );
        onUpdateStreamLink(data.streamLink, data.posterLink);
        onCloseHandler();
      }
    });
    onSubmit();
  };

  const resendConfirmationCodeHandler = () => {
    resendConfirmationCode({
      reference,
    });
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="md"
          rounded={[0, "md"]}
          containerProps={{
            p: ["0", "5"],
          }}
          height={["auto", "auto"]}
          minHeight={["full", "auto"]}
          marginTop={["0", "3.75rem"]}
          marginBottom={["0", "3.75rem"]}
        >
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
            <form onSubmit={confirmGetStreamLinkHandler}>
              <VStack align="stretch" spacing="5">
                <Controller
                  name="code"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl isInvalid={!!errors.code}>
                      <FormLabel>
                        {`${
                          hasEmail
                            ? "Enter the confirmation code sent to your email address"
                            : "Enter your streaming code."
                        }`}
                      </FormLabel>
                      <HStack>
                        <PinInput
                          type="number"
                          onChange={onChange}
                          value={value}
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                      <FormErrorMessage>
                        {!!errors.code && errors.code.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                {hasEmail && (
                  <Box>
                    <Button
                      variant="link"
                      isLoading={isResendingConfirmationCode}
                      onClick={resendConfirmationCodeHandler}
                      loadingText="Resending code..."
                    >
                      Resend confirmation code
                    </Button>
                  </Box>
                )}
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              leftIcon={<TickCircle color="white" variant="Bold" />}
              isLoading={isConfirmingGetStreamLink}
              onClick={confirmGetStreamLinkHandler}
            >
              Confirm
            </Button>
            <Button onClick={onCloseHandler}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmGetStreamLinkModal;

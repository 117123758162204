import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@vime/core/themes/default.css";
import "@vime/core/themes/light.css";
import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Head from "./components/Head";
import theme from "./config/theme";
import { AdminAuthContextProvider } from "./context/AdminAuth";
import AdminHome from "./pages/admin/home/Index";
import AdminLogin from "./pages/admin/login/Index";
import CheckOrder from "./pages/check-ticket/Index";
import Home from "./pages/home/Index";
import StreamMovie from "./pages/stream-movie/Index";
import VerifyTransaction from "./pages/verify-transaction/Index";
import { defaultMovieId } from "./services/firebase";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Head />
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to={`/${defaultMovieId}`} />} />
          <Route path="/:movieId" element={<Home />} />
          <Route
            path="/payments/verify-transaction"
            element={<VerifyTransaction />}
          />
          <Route path="/orders/:movieId/:identifier" element={<CheckOrder />} />
          <Route path="/watch/:movieId/:orderId" element={<StreamMovie />} />
          <Route path="/admin" element={<AdminAuthContextProvider />}>
            <Route path="movies/:movieId" element={<AdminHome />} />
            <Route path="login" element={<AdminLogin />} />
            <Route
              index
              element={<Navigate to={`movies/${defaultMovieId}`} />}
            />
            <Route
              path="*"
              element={<Navigate to={`movies/${defaultMovieId}`} />}
            />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;

import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  addMoviePlaceholderData,
  addPremiereDatesPlaceholderData,
  defaultMovieId,
  doc,
  getDoc,
  getDocs,
  MovieData,
  moviesCollection,
  orderBy,
  PremiereDateData,
  premiereDatesCollection,
  query,
  where,
} from "../services/firebase";

export const useGetMovie = (movieId: string) => {
  const toast = useToast();
  const [isLoadingMovie, setIsLoadingMovie] = useState(false);
  const [movieData, setMovieData] = useState<MovieData>();
  const [premiereDates, setPremiereDates] = useState<PremiereDateData[]>();

  const loadPremiereDates = useCallback(async () => {
    const snapshot = await getDocs(
      query(
        premiereDatesCollection,
        where("movieId", "==", movieId),
        orderBy("date")
      )
    );
    if (!snapshot.empty) {
      const datas = snapshot.docs.map((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        } as PremiereDateData;
        return data;
      });
      setPremiereDates(datas);
    }
  }, [movieId]);

  const loadMovie = useCallback(async () => {
    try {
      if (process.env.NODE_ENV === "development") {
        await addMoviePlaceholderData();
        await addPremiereDatesPlaceholderData();
      }
      setMovieData(undefined);
      setPremiereDates(undefined);
      setIsLoadingMovie(true);
      await loadPremiereDates();
      if (movieId !== defaultMovieId) {
        throw new Error("Movie does not exist.");
      }
      const docSnap = await getDoc(doc(moviesCollection, defaultMovieId));
      if (docSnap.exists()) {
        const data = { ...docSnap.data(), id: docSnap.id } as MovieData;
        setMovieData(data);
        setIsLoadingMovie(false);
      } else {
        throw new Error("No such document!");
      }
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setMovieData(undefined);
      setIsLoadingMovie(false);
    }
  }, [loadPremiereDates, movieId, toast]);

  useEffect(() => {
    loadMovie();
  }, [loadMovie]);

  return {
    isLoadingMovie,
    movieData,
    premiereDates,
  };
};

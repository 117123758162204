import { PremiereDateData, Timestamp } from "../services/firebase";

export const premiereDatePlaceholderData: Partial<PremiereDateData>[] = [
  {
    location: "Terra Kulture",
    venue: "Plot 1376 Tiamiyu Savage St, Victoria Island 106104, Lagos",
    date: Timestamp.fromDate(new Date("2022-02-19 7:00 pm")),
    availableSeats: 80,
  },
  {
    location: "Terra Kulture",
    venue: "Plot 1376 Tiamiyu Savage St, Victoria Island 106104, Lagos",
    date: Timestamp.fromDate(new Date("2022-02-20 7:00 pm")),
    availableSeats: 80,
  },
  {
    location: "Terra Kulture",
    venue: "Plot 1376 Tiamiyu Savage St, Victoria Island 106104, Lagos",
    date: Timestamp.fromDate(new Date("2022-02-26 7:00 pm")),
    availableSeats: 80,
  },
  {
    location: "Terra Kulture",
    venue: "Plot 1376 Tiamiyu Savage St, Victoria Island 106104, Lagos",
    date: Timestamp.fromDate(new Date("2022-02-27 7:00 pm")),
    availableSeats: 80,
  },
];

import { Box, HStack, Image, Link } from "@chakra-ui/react";
import React from "react";

const AdminAuthLayout: React.FC = ({ children }) => {
  return (
    <Box w="full" margin="0 auto" p="5" maxWidth="xl" boxShadow="none">
      <Box mt="5" mb="10">
        <HStack justifyContent="space-between">
          <Link href="/admin">
            <Image
              maxWidth="48"
              src="/images/fruitful-studios-logo-green.png"
              alt="Fruitful Studios Logo"
            />
          </Link>
        </HStack>
      </Box>
      {children}
    </Box>
  );
};

export default AdminAuthLayout;

import {
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetMovie } from "../../../adaptors";
import AdminLayout from "../../../layouts/Admin";
import ConfirmOrderModal from "./components/ConfirmOrderModal";
import PremiereDate from "./components/PremiereDate";

const AdminHome = () => {
  let { movieId } = useParams();
  const {
    isOpen: isConfirmOrderModalOpen,
    onOpen: onOpenConfirmOrderModal,
    onClose: onCloseConfirmOrderModal,
  } = useDisclosure();
  const { movieData, premiereDates } = useGetMovie(movieId ? movieId : "");

  const renderPremiereDates =
    movieData &&
    premiereDates &&
    premiereDates.map((premiereDate) => {
      return <PremiereDate premiereDate={premiereDate} />;
    });

  return (
    <>
      <AdminLayout>
        <VStack align="stretch" spacing="5">
          {!movieData && (
            <Center>
              <Spinner />
            </Center>
          )}
          {!!movieData && (
            <VStack align="stretch" spacing="10">
              <VStack align="stretch">
                <Heading fontSize="2xl">{movieData.title}</Heading>
                <Text fontSize="sm">{movieData.plot}</Text>
                <HStack>
                  <Button colorScheme="green" onClick={onOpenConfirmOrderModal}>
                    Confirm order
                  </Button>
                </HStack>
              </VStack>
              <Divider />
              <VStack align="stretch" spacing="5">
                <Heading fontSize="lg">Premiere dates</Heading>
                <VStack align="stretch">{renderPremiereDates}</VStack>
              </VStack>
            </VStack>
          )}
        </VStack>

        {!!movieData && !!premiereDates && (
          <ConfirmOrderModal
            isOpen={isConfirmOrderModalOpen}
            onClose={onCloseConfirmOrderModal}
            movieData={movieData}
            premiereDates={premiereDates}
          />
        )}
      </AdminLayout>
    </>
  );
};

export default AdminHome;

export function getQueryParam<P = string>(name: string) {
  const search = new URLSearchParams(window.location.search);
  const value = search.get(name) as unknown;
  if (value) {
    return value as P;
  }
  return undefined;
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

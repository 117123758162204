import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface Props {
  forceActiveValue?: string;
  cycle?: boolean;
  interval?: number;
  transition?: number;
  values: any[];
  renderValue: (value: any) => JSX.Element;
}

const Carousel: React.FC<Props> = ({
  forceActiveValue,
  cycle = true,
  interval = 5000,
  transition = 5000,
  values,
  renderValue,
}) => {
  const [active, setActive] = useState(
    forceActiveValue ? parseInt(forceActiveValue) : 0
  );
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false);

  useEffect(() => {
    if (forceActiveValue) return;
    if (!cycle) return;
    const timeout = setTimeout(() => {
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(transition);
        setActive(active === values.length - 1 ? 0 : active + 1);
        setFirstTransitionIsDone(true);
      }
      if (cycle) {
        startImageTransition();
      }
    }, interval);

    return () => clearTimeout(timeout);
  }, [
    active,
    cycle,
    firstTransitionIsDone,
    forceActiveValue,
    values.length,
    interval,
    transition,
  ]);

  useEffect(() => {
    if (!forceActiveValue) return;
    setActive(parseInt(forceActiveValue));
  }, [active, forceActiveValue]);

  return (
    <>
      {values.map((value, index) => (
        <Box
          key={`${value}-${index}`}
          opacity={active === index ? 1 : 0}
          height={active === index ? "auto" : 0}
          overflow={active === index ? "auto" : "hidden"}
          transition={`opacity ${transition}ms`}
        >
          {renderValue(value)}
        </Box>
      ))}
    </>
  );
};

export default Carousel;

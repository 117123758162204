import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  movieId: string;
}

interface ConfirmOrderInput {
  identifier: string;
}

const validationSchema = Joi.object<ConfirmOrderInput>({
  identifier: Joi.string().required().min(5),
});

const ConfirmOrderModal: React.FC<Props> = ({ isOpen, onClose, movieId }) => {
  const navigate = useNavigate();
  const defaultValues: Partial<ConfirmOrderInput> = {
    identifier: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConfirmOrderInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });

  const confirmOrderHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      navigate(`/orders/${movieId}/${input.identifier}`);
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="md"
        rounded={[0, "md"]}
        containerProps={{
          p: ["0", "5"],
        }}
        height={["auto", "auto"]}
        minHeight={["full", "auto"]}
        marginTop={["0", "3.75rem"]}
        marginBottom={["0", "3.75rem"]}
      >
        <ModalHeader>Confirm order</ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
          <form onSubmit={confirmOrderHandler}>
            <VStack align="stretch" spacing="5">
              <FormControl isInvalid={!!errors.identifier}>
                <FormLabel>Enter your email address or order ID</FormLabel>
                <Input
                  placeholder="Email address or order ID"
                  {...register("identifier")}
                />
                <FormErrorMessage>
                  {!!errors.identifier && errors.identifier.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={confirmOrderHandler}>
            Continue
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmOrderModal;

import { Box, Button, chakra, HStack, Text } from "@chakra-ui/react";
import { Alarm } from "iconsax-react";
import moment from "moment";
import React from "react";
import { useSendReminderEmails } from "../../../../adaptors";
import { PremiereDateData } from "../../../../services/firebase-types";

interface Props {
  premiereDate: PremiereDateData;
}

const PremiereDate: React.FC<Props> = ({ premiereDate }) => {
  const { id, date, availableSeats } = premiereDate;
  const isDatePassed = moment().isAfter(moment.unix(date.seconds));
  const { sendReminderEmails, isSendingReminderEmails } =
    useSendReminderEmails();

  const sendReminderEmailsHandler = () => {
    if (
      window.confirm(
        "Are you really sure you want to continue with this action?"
      )
    ) {
      sendReminderEmails({
        premiereDate: premiereDate.id,
      });
    }
  };

  return (
    <Box key={id} borderWidth={1} rounded="md" px="5" py="3">
      <Text>{moment(moment.unix(date.seconds)).format("LLL")}</Text>
      <Text fontSize="sm">
        <chakra.span fontWeight="semibold">{availableSeats}</chakra.span>
        {` seat(s) left`}
      </Text>
      <Text fontSize="sm" color="gray.600">
        {premiereDate.location}
      </Text>
      <Text fontSize="sm" color="gray.600">
        {premiereDate.venue}
      </Text>
      {!isDatePassed && (
        <HStack mt="3">
          <Button
            colorScheme="green"
            variant="solid"
            size="sm"
            leftIcon={<Alarm size={16} color="white" variant="Bold" />}
            isLoading={isSendingReminderEmails}
            onClick={sendReminderEmailsHandler}
          >
            Send reminder emails
          </Button>
        </HStack>
      )}
    </Box>
  );
};

export default PremiereDate;

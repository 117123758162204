import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Calendar, Refresh2 } from "iconsax-react";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetOrder } from "../../adaptors";
import Head from "../../components/Head";
import { OrderData, PremiereDateData } from "../../services/firebase";
import ChangeTicketDateModal from "./components/ChangeTicketDateModal";

const CheckOrder = () => {
  let { movieId, identifier } = useParams();

  const {
    isOpen: isChangeTicketDateModalOpen,
    onOpen: onOpenChangeTicketDateModal,
    onClose: onCloseChangeTicketDateModal,
  } = useDisclosure();

  const [modalOrderData, setModalOrderData] = useState<OrderData>();

  const {
    isLoadingOrder,
    isOrderError,
    movieData,
    premiereDates,
    orderData: orderDatas,
    reloadOrder,
  } = useGetOrder(movieId, identifier);

  if (!movieId || !identifier) {
    return null;
  }

  const reloadOrderhandler = () => {
    reloadOrder();
  };

  let isLastPremiereDatePassed = false;
  if (premiereDates && premiereDates.length) {
    const date = premiereDates[premiereDates.length - 1].date;
    isLastPremiereDatePassed = moment().isAfter(moment.unix(date.seconds));
  }

  const renderOrders =
    movieData &&
    premiereDates &&
    orderDatas?.map((orderData, index) => {
      let premiereDate: PremiereDateData | undefined;
      premiereDate = premiereDates.find(
        (value) => value.id === orderData.premiereDate
      );

      const onOpenChangeTicketDateModalHandler = () => {
        setModalOrderData(orderData);
        onOpenChangeTicketDateModal();
      };

      return (
        <>
          <VStack align="stretch">
            <Box rounded="md" overflow="hidden">
              <Head title="Order details" />
              <Flex
                bg="black"
                p="5"
                borderBottomWidth="thin"
                borderColor="white"
                borderStyle="dashed"
                position="relative"
                justifyContent="center"
              >
                <Box
                  position="absolute"
                  w="4"
                  h="4"
                  rounded="full"
                  bg="white"
                  bottom="-2"
                  left="-2"
                />
                <Box
                  position="absolute"
                  w="4"
                  h="4"
                  rounded="full"
                  bg="white"
                  bottom="-2"
                  right="-2"
                />
                <Image
                  maxHeight="10"
                  src={movieData.lightLogoUrl}
                  alt={`${movieData.title} Logo`}
                />
              </Flex>
              <Box bg="gray.100" p="5">
                <VStack spacing="2" align="stretch">
                  <Box>
                    <Text
                      textTransform="uppercase"
                      color="gray.500"
                      fontSize="xs"
                    >
                      {`TID: ${orderData.id}`}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      textTransform="uppercase"
                      fontSize="sm"
                      fontWeight="semibold"
                    >
                      {orderData.fullname}
                    </Text>
                    <Text color="gray.500" fontSize="xs">
                      NAME
                    </Text>
                  </Box>
                  <Wrap spacing="5">
                    {!!premiereDate && (
                      <WrapItem>
                        <Box>
                          <Text
                            textTransform="uppercase"
                            fontSize="sm"
                            fontWeight="semibold"
                          >
                            {moment(
                              moment.unix(premiereDate.date.seconds)
                            ).format("YYYY-MM-DD")}
                          </Text>
                          <Text color="gray.500" fontSize="xs">
                            DATE
                          </Text>
                        </Box>
                      </WrapItem>
                    )}
                    {!!premiereDate && (
                      <WrapItem>
                        <Box>
                          <Text
                            textTransform="uppercase"
                            fontSize="sm"
                            fontWeight="semibold"
                          >
                            {moment(
                              moment.unix(premiereDate.date.seconds)
                            ).format("h:mm a")}
                          </Text>
                          <Text color="gray.500" fontSize="xs">
                            TIME
                          </Text>
                        </Box>
                      </WrapItem>
                    )}
                    <WrapItem>
                      <Box>
                        <Text
                          textTransform="uppercase"
                          fontSize="sm"
                          fontWeight="semibold"
                        >
                          {`${orderData.seats} ${
                            orderData.seats > 1 ? "(Couple)" : " (Single)"
                          }`}
                        </Text>
                        <Text color="gray.500" fontSize="xs">
                          {`SEAT${orderData.seats > 1 ? "S" : ""}`}
                        </Text>
                      </Box>
                    </WrapItem>
                  </Wrap>
                  {premiereDate && (
                    <Box>
                      <Text
                        textTransform="uppercase"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        {premiereDate.location} <br />
                        {premiereDate.venue}
                      </Text>
                      <Text color="gray.500" fontSize="xs">
                        VENUE
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
            </Box>
            <Box>
              {!isLastPremiereDatePassed && !orderData.attendance && (
                <Button
                  colorScheme="green"
                  variant="solid"
                  width="full"
                  leftIcon={<Calendar size={20} color="white" variant="Bold" />}
                  onClick={onOpenChangeTicketDateModalHandler}
                >
                  Change order date
                </Button>
              )}
            </Box>
          </VStack>
          {index !== orderDatas.length - 1 && <Divider />}
        </>
      );
    });

  return (
    <>
      <Modal
        size="full"
        isOpen={true}
        onClose={() => null}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
      >
        <ModalContent maxWidth="xl" boxShadow="none">
          <ModalBody borderBottomWidth={1} py="5">
            <Box mt="5" mb="10">
              <Link href="/">
                <Image
                  maxWidth="48"
                  src="/images/fruitful-studios-logo-green.png"
                  alt="Fruitful Studios Logo"
                />
              </Link>
            </Box>
            <VStack spacing="5" align="stretch">
              {isLoadingOrder && (
                <Alert status="info" variant="solid" rounded="md">
                  <Box>
                    <Spinner mr="3" />
                  </Box>
                  {`Please wait while we load your order.`}
                </Alert>
              )}
              {isOrderError && (
                <Alert status="error" variant="solid" rounded="md">
                  <AlertIcon />
                  {`An error occured while attempting to load order.`}
                </Alert>
              )}
              {!isLoadingOrder && !isOrderError && !orderDatas && (
                <Alert status="info" variant="solid" rounded="md">
                  <AlertIcon />
                  {`No order was found for the provided email or order ID.`}
                </Alert>
              )}
              {isOrderError && (
                <Button
                  alignSelf="center"
                  leftIcon={<Refresh2 size="20" color="white" />}
                  colorScheme="blackAlpha"
                  onClick={reloadOrderhandler}
                >
                  Retry
                </Button>
              )}

              {movieData && premiereDates && orderDatas && orderDatas.length && (
                <Box>
                  <VStack align="stretch" spacing={10}>
                    {renderOrders}
                  </VStack>
                  {modalOrderData && (
                    <ChangeTicketDateModal
                      isOpen={isChangeTicketDateModalOpen}
                      onClose={onCloseChangeTicketDateModal}
                      premiereDates={premiereDates}
                      orderData={modalOrderData}
                      reloadOrder={reloadOrderhandler}
                    />
                  )}
                </Box>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CheckOrder;

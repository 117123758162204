import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { doc, ordersCollection, updateDoc } from "../services/firebase";

export const useMarkAttendance = () => {
  const toast = useToast();
  const [isMarkingAttendance, setIsMarkingAttendance] = useState(false);
  const [isUnmarkingAttendance, setIsUnmarkingAttendance] = useState(false);

  const markAttendance = async (orderId: string, attendance: number) => {
    try {
      setIsMarkingAttendance(true);
      await updateDoc(doc(ordersCollection, orderId), {
        attendance,
      });
      setIsMarkingAttendance(false);
      const message = "Attendance has been marked.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setIsMarkingAttendance(false);
    }
  };

  const unmarkAttendance = async (orderId: string, attendance: number) => {
    try {
      setIsUnmarkingAttendance(true);
      await updateDoc(doc(ordersCollection, orderId), {
        attendance,
      });
      setIsUnmarkingAttendance(false);
      const message = "Attendance has been unmarked.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setIsUnmarkingAttendance(false);
    }
  };

  return {
    isMarkingAttendance,
    isUnmarkingAttendance,
    markAttendance,
    unmarkAttendance,
  };
};

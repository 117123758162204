import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ChangeTicketDateInput,
  ChangeTicketDateOutput,
  changeTicketDateService,
} from "../services/firebase";

export const useChangeTicketDate = () => {
  const toast = useToast();
  const [isChangingTicketDate, setIsChangingTicketDate] = useState(false);
  const [changeTicketDateResultData, setChangeTicketDateResultData] =
    useState<ChangeTicketDateOutput>();

  const changeTicketDate = async (data: ChangeTicketDateInput) => {
    try {
      setChangeTicketDateResultData(undefined);
      setIsChangingTicketDate(true);
      const result = await changeTicketDateService(data);
      setChangeTicketDateResultData(result.data);
      setIsChangingTicketDate(false);
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setChangeTicketDateResultData(undefined);
      setIsChangingTicketDate(false);
    }
  };

  return {
    isChangingTicketDate,
    changeTicketDateResultData,
    changeTicketDate,
  };
};

import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  SlideFade,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { CloseCircle, Facebook, Instagram, Play, Youtube } from "iconsax-react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useConfirmGetStreamLink,
  useGetMovie,
  useGetStreamLink,
} from "../../adaptors";
import Head from "../../components/Head";
import { delay } from "../../utils/helpers";
import BackgroundImageCarousel from "../home/components/BackgroundImageCarousel";
import ConfirmGetStreamLinkModal from "./components/ConfirmGetStreamLinkModal";
import InvitationModal from "./components/InvitationModal";
import ReviewModal from "./components/ReviewModal";
import VideoPlayer from "./components/VideoPlayer";

const date = new Date();

enum PlayingStatus {
  unwatched,
  watching,
  watched,
}

const StreamMovie = () => {
  let { movieId, orderId } = useParams();
  const [streamLink, setStreamLink] = useState<string>();
  const [posterLink, setPosterLink] = useState<string>();
  const {
    isOpen: isConfirmGetStreamLinkModalOpen,
    onOpen: onOpenConfirmGetStreamLinkModal,
    onClose: onCloseConfirmGetStreamLinkModal,
  } = useDisclosure();
  const [getStreamLinkReference, setGetStreamLinkReference] =
    useState<string>();
  const { movieData } = useGetMovie(movieId ? movieId : "");
  const { getStreamLink, isGettingStreamLink } = useGetStreamLink();
  const { confirmGetStreamLink, isConfirmingGetStreamLink } =
    useConfirmGetStreamLink();
  const {
    isOpen: isReviewModalOpen,
    onOpen: onOpenReviewModal,
    onClose: onCloseReviewModal,
  } = useDisclosure();
  const {
    isOpen: isInvitationModalOpen,
    onOpen: onOpenInvitationModal,
    onClose: onCloseInvitationModal,
  } = useDisclosure();
  const [hasEmail, setHasEmail] = useState<boolean>();
  const [playingStatus, setPlayingStatus] = useState<PlayingStatus>(
    PlayingStatus.unwatched
  );
  const [hasAskedToReview, setHasAskedToReview] = useState(false);
  const [hasAskedForRecommendation, setHasAskedForRecommendation] =
    useState(false);
  const [isEndingMessageBlurred, setIsEndingMessageBlurred] = useState(false);

  const handleSavedTimeChange = useCallback((id) => {
    const savedOldTime = window.localStorage.getItem(`${id}_current_time`);
    if (savedOldTime) {
      if (Number(savedOldTime) === 0) {
        setPlayingStatus(PlayingStatus.watched);
      } else {
        setPlayingStatus(PlayingStatus.watching);
      }
    } else {
      setPlayingStatus(PlayingStatus.unwatched);
    }
  }, []);

  useEffect(() => {
    if (movieData?.id) {
      handleSavedTimeChange(movieData?.id);
    }
  }, [handleSavedTimeChange, movieData?.id]);

  const handleCloseReviewModal = async () => {
    onCloseReviewModal();
    setHasAskedToReview(true);
    if (streamLink && !hasAskedForRecommendation) {
      await delay(1000);
      const confirm = window.confirm(
        "Do you also want to recommmend this movie to others?"
      );
      if (confirm) {
        onOpenInvitationModal();
      } else {
        setIsEndingMessageBlurred(true);
      }
    }
    if (streamLink && hasAskedForRecommendation) {
      setIsEndingMessageBlurred(true);
    }
  };

  const handleCloseInvitationModal = async () => {
    onCloseInvitationModal();
    setHasAskedForRecommendation(true);
    if (streamLink && !hasAskedToReview) {
      await delay(1000);
      const confirm = window.confirm(
        "Do you also want to leave a review for this movie?"
      );
      if (confirm) {
        onOpenReviewModal();
      } else {
        setIsEndingMessageBlurred(true);
      }
    }
    if (streamLink && hasAskedToReview) {
      setIsEndingMessageBlurred(true);
    }
  };

  const getStreamLinkHandler = async () => {
    if (!movieId || !orderId) {
      return;
    }
    const lastConfirmationReference = window.localStorage.getItem(
      `last_confirmation_reference_${orderId}`
    );
    if (lastConfirmationReference) {
      const streamData = await confirmGetStreamLink({
        code: "",
        reference: lastConfirmationReference,
        isOldReference: true,
        orderId,
        movieId,
      });
      if (streamData && !streamData.confirmationRequired) {
        setStreamLink(streamData.streamLink);
        setPosterLink(streamData.posterLink);
        return;
      }
    }
    const data = await getStreamLink({
      movieId,
      orderId,
    });
    if (data) {
      setGetStreamLinkReference(data.reference);
      setHasEmail(data.hasEmail);
      onOpenConfirmGetStreamLinkModal();
    }
  };

  const resetPageHandler = () => {
    setGetStreamLinkReference(undefined);
    setHasEmail(undefined);
    setStreamLink(undefined);
    setPosterLink(undefined);
  };

  const updateStreamLink = (link: string, posterLink: string) => {
    setStreamLink(link);
    setPosterLink(posterLink);
  };

  return (
    <>
      <Head title={movieData?.title} description={movieData?.plot} />
      <Box backgroundColor="gray.900">
        <Box position="absolute" width="full" height="full">
          {!!movieData && (
            <BackgroundImageCarousel images={movieData.backgroundPosterUrls} />
          )}
          <Box
            position="absolute"
            top={0}
            left={0}
            height="full"
            width="full"
            backgroundColor="black"
            opacity={0.7}
          ></Box>
        </Box>
        <Flex direction="column" minHeight="100vh" position="relative">
          <Box>
            <Container maxWidth="container.xl">
              <Box py="5" pt="10">
                <Wrap spacing="5" justify={["center", "space-between"]}>
                  <WrapItem>
                    <Box>
                      <Image
                        maxWidth="48"
                        src="/images/fruitful-studios-logo.png"
                        alt="Fruitful Studios Logo"
                      />
                    </Box>
                  </WrapItem>
                  <WrapItem>
                    <HStack justifyContent="end" space="2">
                      <Link
                        href="https://facebook.com/fruitfulstudios"
                        isExternal
                      >
                        <IconButton
                          colorScheme="facebook"
                          aria-label="Facebook"
                          borderRadius="full"
                          icon={<Facebook color="white" variant="Bold" />}
                        />
                      </Link>
                      <Link
                        href="https://instagram.com/fruitfulstudios"
                        isExternal
                      >
                        <IconButton
                          colorScheme="pink"
                          aria-label="Instagram"
                          borderRadius="full"
                          icon={<Instagram color="white" variant="Bold" />}
                        />
                      </Link>
                      <Link
                        href="https://youtube.com/channel/UCG6MyiaTJdPRO1oSImbHa_g"
                        isExternal
                      >
                        <IconButton
                          colorScheme="red"
                          aria-label="Youtube"
                          borderRadius="full"
                          icon={<Youtube color="white" variant="Bold" />}
                        />
                      </Link>
                    </HStack>
                  </WrapItem>
                </Wrap>
              </Box>
            </Container>
          </Box>
          <Flex grow={1} direction="column" justifyContent="center">
            <Container maxWidth="container.xl">
              {!movieData && (
                <Center>
                  <Spinner size="xl" color="white" />
                </Center>
              )}
              {!!movieData && (
                <VStack
                  spacing="5"
                  alignItems={["center", "center"]}
                  textAlign={["center", "center"]}
                >
                  <Box alignSelf={["stretch", "unset"]}>
                    <Stack
                      spacing="5"
                      direction={["column"]}
                      alignItems={["center"]}
                      justify={["center"]}
                      maxWidth="md"
                    >
                      <Box maxWidth="sm">
                        <Image
                          src={movieData.lightLogoUrl}
                          alt={`${movieData.title} Logo`}
                        />
                      </Box>
                      <Text color="white" mt="5">
                        {movieData.plot}
                      </Text>
                      <VStack justify="center" align="stretch">
                        <Button
                          colorScheme="green"
                          variant="solid"
                          size="lg"
                          leftIcon={<Play color="white" variant="Bold" />}
                          isLoading={
                            isGettingStreamLink || isConfirmingGetStreamLink
                          }
                          loadingText="Please wait..."
                          onClick={getStreamLinkHandler}
                        >
                          {playingStatus === PlayingStatus.unwatched &&
                            "Start movie"}
                          {playingStatus === PlayingStatus.watching &&
                            "Continue watching"}
                          {playingStatus === PlayingStatus.watched &&
                            "Watch again"}
                        </Button>
                      </VStack>
                      {playingStatus !== PlayingStatus.unwatched && (
                        <>
                          <Text color="white">OR</Text>
                          <Box alignSelf={["stretch", "unset"]}>
                            <Text color="white" align={"center"}>
                              {`Kindly take a moment to `}
                              <Link
                                color="green.500"
                                fontWeight="bold"
                                variant="link"
                                onClick={onOpenReviewModal}
                              >
                                {`leave a review`}
                              </Link>
                              {` and `}
                              <Link
                                color="green.500"
                                fontWeight="bold"
                                variant="link"
                                onClick={onOpenInvitationModal}
                              >
                                {`recommend this movie`}
                              </Link>
                              {` to others. `}
                            </Text>
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Box>
                </VStack>
              )}
            </Container>
          </Flex>
          <Box>
            <Container maxWidth="container.xl">
              <Box py="5">
                <Text color="gray.200" textAlign={["center", "center"]}>
                  © {date.getFullYear()} Fruitful Studios. All rights reserved.
                </Text>
              </Box>
            </Container>
          </Box>
        </Flex>
      </Box>

      {orderId && movieId && getStreamLinkReference && (
        <ConfirmGetStreamLinkModal
          isOpen={isConfirmGetStreamLinkModalOpen}
          onClose={onCloseConfirmGetStreamLinkModal}
          reference={getStreamLinkReference}
          onUpdateStreamLink={updateStreamLink}
          hasEmail={!!hasEmail}
          orderId={orderId}
          movieId={movieId}
        />
      )}

      {!!movieData && streamLink && posterLink && (
        <Box
          display={
            isReviewModalOpen || isInvitationModalOpen ? "none" : "initial"
          }
        >
          <VideoPlayer
            src={streamLink}
            poster={movieData.videoPosterUrl}
            title={movieData.title}
            movieId={movieData.id}
            style={{
              border: 0,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
              overflow: "hidden",
              background: "#000000",
            }}
            onClose={resetPageHandler}
            onSavedTimeChange={handleSavedTimeChange}
            endingTime={movieData.endCreditsTime}
            isEndingMessageFocused={isReviewModalOpen || isInvitationModalOpen}
            isEndingMessageBlurred={isEndingMessageBlurred}
            renderEndingMessage={({ isVisible, close, pauseVideo }) => (
              <Box
                position="absolute"
                top={0}
                right={0}
                zIndex={2147483647}
                width="full"
                height={isVisible ? "auto" : 0}
                overflow={isVisible ? "auto" : "hidden"}
              >
                <SlideFade in={isVisible} offsetY={-20}>
                  <HStack
                    bg="#fdebce"
                    fontFamily="body"
                    lineHeight="normal"
                    px={2.5}
                    py={4}
                    shadow="lg"
                    opacity={0.9}
                  >
                    <Text align="center" flexGrow={1}>
                      {`Thanks for watching. Please take a moment to `}
                      <Link
                        color="green.500"
                        fontWeight="bold"
                        borderBottom="1px"
                        variant="link"
                        onClick={() => {
                          pauseVideo();
                          onOpenReviewModal();
                        }}
                      >
                        {`leave a review`}
                      </Link>
                      {` and `}
                      <Link
                        color="green.500"
                        fontWeight="bold"
                        borderBottom="1px"
                        variant="link"
                        onClick={() => {
                          pauseVideo();
                          onOpenInvitationModal();
                        }}
                      >
                        {`recommend this movie`}
                      </Link>
                      {` to others. `}
                    </Text>
                    <IconButton
                      colorScheme="blackAlpha"
                      variant="ghost"
                      aria-label="Close"
                      icon={<CloseCircle />}
                      onClick={close}
                    />
                  </HStack>
                </SlideFade>
              </Box>
            )}
          />
        </Box>
      )}

      {!!movieData && orderId && playingStatus !== PlayingStatus.unwatched && (
        <>
          <ReviewModal
            isOpen={isReviewModalOpen}
            onClose={handleCloseReviewModal}
            movieId={movieData.id}
            orderId={orderId}
            movieTitle={movieData.title}
          />
          <InvitationModal
            isOpen={isInvitationModalOpen}
            onClose={handleCloseInvitationModal}
            movieId={movieData.id}
            orderId={orderId}
          />
        </>
      )}
    </>
  );
};

export default StreamMovie;

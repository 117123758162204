import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { Login } from "iconsax-react";
import Joi from "joi";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import { useAdminLogin } from "../../../adaptors";
import AdminAuthContext from "../../../context/AdminAuth";
import AdminAuthLayout from "../../../layouts/AdminAuth";

interface UseForm {
  email: string;
}

const validationSchema = Joi.object<UseForm>({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } }),
});

const AdminLogin = () => {
  const { user } = useContext(AdminAuthContext);
  const location = useLocation();

  let from = "/admin";
  if (location.state) {
    const locationState = location.state as any;
    if (locationState.from) {
      const fromLocation = locationState.from as Location;
      from = fromLocation.pathname;
    }
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UseForm>({
    reValidateMode: "onChange",
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues: {
      email: "",
    },
  });

  const { adminLogin, isAdminLogingIn } = useAdminLogin();

  const signInHandler = () => {
    const onSubmit = handleSubmit(async (data) => {
      adminLogin(data);
    });
    onSubmit();
  };

  const signInFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signInHandler();
  };

  if (user) {
    return <Navigate to={from} replace />;
  }

  return (
    <AdminAuthLayout>
      <VStack align="stretch" spacing="5">
        <Heading fontSize="2xl">Log in</Heading>
        <Box>
          <form onSubmit={signInFormHandler}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>{`Email address`}</FormLabel>
                <Input id="email" type="email" {...register("email")} />
                <FormErrorMessage>
                  {!!errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <HStack spacing={10}>
                <Button
                  leftIcon={<Login size={20} color="white" />}
                  colorScheme="green"
                  type="submit"
                  isLoading={isAdminLogingIn}
                  onClick={signInHandler}
                >
                  {`Log in`}
                </Button>
              </HStack>
            </Stack>
          </form>
        </Box>
      </VStack>
    </AdminAuthLayout>
  );
};

export default AdminLogin;

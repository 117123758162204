import { useToast } from "@chakra-ui/react";
import Joi from "joi";
import { useCallback, useEffect, useState } from "react";
import {
  doc,
  getDoc,
  getDocs,
  MovieData,
  moviesCollection,
  orderBy,
  OrderData,
  ordersCollection,
  PremiereDateData,
  premiereDatesCollection,
  query,
  where,
} from "../services/firebase";

export const useGetOrder = (movieId?: string, identifier?: string) => {
  const toast = useToast();
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [isOrderError, setIsOrderError] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>();
  const [movieData, setMovieData] = useState<MovieData>();
  const [premiereDates, setPremiereDates] = useState<PremiereDateData[]>();

  const loadMovie = async (movieId: string) => {
    const docSnap = await getDoc(doc(moviesCollection, movieId));
    if (docSnap.exists()) {
      const data = { ...docSnap.data(), id: docSnap.id } as MovieData;
      setMovieData(data);
    } else {
      throw new Error("Movie was not found!");
    }
  };

  const loadPremiereDates = async (movieId: string) => {
    const snapshot = await getDocs(
      query(
        premiereDatesCollection,
        where("movieId", "==", movieId),
        orderBy("date")
      )
    );
    if (!snapshot.empty) {
      const datas = snapshot.docs.map((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        } as PremiereDateData;
        return data;
      });
      setPremiereDates(datas);
    } else {
      throw new Error("Movie was not found!");
    }
  };

  const loadOrder = useCallback(async () => {
    if (!movieId || !identifier) {
      return;
    }
    try {
      setOrderData(undefined);
      setIsLoadingOrder(true);
      setIsOrderError(false);
      if (!movieData) {
        await loadMovie(movieId);
      }

      if (!premiereDates) {
        await loadPremiereDates(movieId);
      }

      const emailValidation = Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .validate(identifier);
      if (emailValidation.error) {
        const docSnap = await getDoc(
          doc(ordersCollection, identifier.toLowerCase())
        );
        if (docSnap.exists()) {
          const data = { ...docSnap.data(), id: docSnap.id } as OrderData;
          setOrderData([data]);
        }
      } else {
        const querySnap = await getDocs(
          query(
            ordersCollection,
            where("email", "==", identifier.toLowerCase())
          )
        );
        if (querySnap.size > 0) {
          const datas = querySnap.docs.map((doc) => {
            const data = {
              ...doc.data(),
              id: doc.id,
            } as OrderData;
            return data;
          });
          setOrderData(datas);
        }
      }
      setIsLoadingOrder(false);
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setOrderData(undefined);
      setIsLoadingOrder(false);
      setIsOrderError(true);
    }
  }, [identifier, movieData, movieId, premiereDates, toast]);

  const reloadOrder = () => {
    loadOrder();
  };

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  return {
    isLoadingOrder,
    reloadOrder,
    isOrderError,
    movieData,
    premiereDates,
    orderData,
  };
};

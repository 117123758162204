import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  User,
} from "firebase/auth";
import {
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { moviePlaceholderData } from "../placeholder-data/movie";
import { premiereDatePlaceholderData } from "../placeholder-data/premiere-dates";
import {
  AddReviewInput,
  AddReviewOutput,
  ChangeTicketDateInput,
  ChangeTicketDateOutput,
  ConfirmBookingInput,
  ConfirmBookingOutput,
  ConfirmChangeTicketDateInput,
  ConfirmChangeTicketDateOutput,
  ConfirmGetStreamLinkInput,
  ConfirmGetStreamLinkOutput,
  GetStreamLinkInput,
  GetStreamLinkOutput,
  InitializeTransactionInput,
  InitializeTransactionOutput,
  ResendConfirmationCodeInput,
  ResendConfirmationCodeOutput,
  SendInvitationInput,
  SendInvitationOutput,
  SendReminderEmailsInput,
  SendReminderEmailsOutput,
  VerifyTransactionInput,
  VerifyTransactionOutput,
} from "./firebase-types";

enum AppEnv {
  development = "development",
  staging = "preview",
  production = "production",
}
const appEnv = process.env.REACT_APP_VERCEL_ENV as AppEnv;

let firebaseConfig = {
  apiKey: "AIzaSyCHVfbVt0Y0FM5Sl9OaXSuZOpo5_6pbNcM",
  authDomain: "fruitfulstudios-staging.firebaseapp.com",
  projectId: "fruitfulstudios-staging",
  storageBucket: "fruitfulstudios-staging.appspot.com",
  messagingSenderId: "276687211616",
  appId: "1:276687211616:web:8c49e6461312c985b88705",
  measurementId: "G-BKTKFK1JHH",
};

let domain = "http://localhost:3000";
let adminEmail = "info@fruitfulstudios.com";

if (process.env.NODE_ENV === AppEnv.development) {
  adminEmail = process.env.REACT_APP_ADMIN_EMAIL || "";
}

if (appEnv === AppEnv.staging) {
  domain = "https://staging.fruitfulstudios.com";
}

if (appEnv === AppEnv.production) {
  domain = "https://fruitfulstudios.com";
  firebaseConfig = {
    apiKey: "AIzaSyABWQf3IR9RSIOeO3-mn7xxtBdoB0HM96M",
    authDomain: "fruitfulstudios-27855.firebaseapp.com",
    projectId: "fruitfulstudios-27855",
    storageBucket: "fruitfulstudios-27855.appspot.com",
    messagingSenderId: "242403285332",
    appId: "1:242403285332:web:ab90541d0537e8d89d50c1",
    measurementId: "G-9JLZJEHGHC",
  };
}

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "europe-west1");
const db = getFirestore(app);
const auth = getAuth(app);

const isDev = process.env.NODE_ENV === "development";
const functionsHost = process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST;
const functionsPort = process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT;
const firestoreHost = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
const firestorePort = process.env.REACT_APP_FIRESTORE_EMULATOR_PORT;
const authHost = process.env.REACT_APP_AUTH_EMULATOR_HOST;
const authPort = process.env.REACT_APP_AUTH_EMULATOR_PORT;

if (isDev) {
  if (!functionsHost || !functionsPort) {
    throw new Error("Functions emulator host or port invalid.");
  }
  connectFunctionsEmulator(functions, functionsHost, Number(functionsPort));

  if (!firestoreHost || !firestorePort) {
    throw new Error("Firestore emulator host or port invalid.");
  }
  connectFirestoreEmulator(db, firestoreHost, Number(firestorePort));

  if (!authHost || !authPort) {
    throw new Error("Auth emulator host or port invalid.");
  }
  connectAuthEmulator(auth, `http://${authHost}:${authPort}`, {
    disableWarnings: true,
  });
}

const initializeTransactionService = httpsCallable<
  InitializeTransactionInput,
  InitializeTransactionOutput
>(functions, "initializeTransaction");

const resendConfirmationCodeService = httpsCallable<
  ResendConfirmationCodeInput,
  ResendConfirmationCodeOutput
>(functions, "resendConfirmationCode");

const verifyTransactionService = httpsCallable<
  VerifyTransactionInput,
  VerifyTransactionOutput
>(functions, "verifyTransaction");

const confirmBookingService = httpsCallable<
  ConfirmBookingInput,
  ConfirmBookingOutput
>(functions, "confirmBooking");

const changeTicketDateService = httpsCallable<
  ChangeTicketDateInput,
  ChangeTicketDateOutput
>(functions, "changeTicketDate");

const confirmChangeTicketDateService = httpsCallable<
  ConfirmChangeTicketDateInput,
  ConfirmChangeTicketDateOutput
>(functions, "confirmChangeTicketDate");

const getStreamLinkService = httpsCallable<
  GetStreamLinkInput,
  GetStreamLinkOutput
>(functions, "getStreamLink");

const confirmGetStreamLinkService = httpsCallable<
  ConfirmGetStreamLinkInput,
  ConfirmGetStreamLinkOutput
>(functions, "confirmGetStreamLink");

const sendReminderEmailsService = httpsCallable<
  SendReminderEmailsInput,
  SendReminderEmailsOutput
>(functions, "sendReminderEmails");

const addReviewService = httpsCallable<AddReviewInput, AddReviewOutput>(
  functions,
  "addReview"
);

const sendInvitationService = httpsCallable<
  SendInvitationInput,
  SendInvitationOutput
>(functions, "sendInvitation");

const moviesCollection = collection(db, "movies");
const ordersCollection = collection(db, "orders");
const premiereDatesCollection = collection(db, "premiere-dates");
const defaultMovieId = "something-special";

const addMoviePlaceholderData = async () => {
  if (!defaultMovieId) {
    return;
  }
  const snapshot = await getDoc(doc(moviesCollection, defaultMovieId));
  if (snapshot.exists()) {
    return;
  }
  setDoc(doc(moviesCollection, defaultMovieId), moviePlaceholderData);
};

const addPremiereDatesPlaceholderData = async () => {
  if (!defaultMovieId) {
    return;
  }
  const snapshot = await getDocs(
    query(premiereDatesCollection, where("movieId", "==", defaultMovieId))
  );
  if (snapshot.size === premiereDatePlaceholderData.length) {
    return;
  }
  const premiereDatesBatch = writeBatch(db);
  premiereDatePlaceholderData.forEach((data) => {
    data.movieId = defaultMovieId;
    premiereDatesBatch.set(doc(premiereDatesCollection), data);
  });
  premiereDatesBatch.commit();
};

const ageRanges = [
  "18 - 27",
  "28 - 37",
  "38 - 47",
  "48 - 57",
  "58 - 67",
  "68+",
];

export * from "./firebase-types";
export {
  initializeTransactionService,
  resendConfirmationCodeService,
  verifyTransactionService,
  confirmBookingService,
  changeTicketDateService,
  confirmChangeTicketDateService,
  getStreamLinkService,
  confirmGetStreamLinkService,
  sendReminderEmailsService,
  addReviewService,
  sendInvitationService,
};
export {
  domain,
  adminEmail,
  defaultMovieId,
  moviesCollection,
  ordersCollection,
  premiereDatesCollection,
  addMoviePlaceholderData,
  addPremiereDatesPlaceholderData,
  ageRanges,
};
export {
  getDoc,
  getDocs,
  doc,
  query,
  where,
  limit,
  orderBy,
  updateDoc,
  Timestamp,
};
export {
  auth,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  signOut,
};
export type { User };

import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  adminEmail,
  AdminLoginInput,
  AdminLoginOutput,
  auth,
  domain,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "../services/firebase";

export const useAdminLogin = () => {
  const toast = useToast();
  const [isAdminLogingIn, setIsAdminLogingIn] = useState(false);
  const [adminLoginResultData, setAdminLoginResultData] =
    useState<AdminLoginOutput>();

  const adminLogin = async (data: AdminLoginInput) => {
    try {
      if (data.email !== adminEmail) {
        throw new Error("User not allowed to perform this action.");
      }
      setAdminLoginResultData(undefined);
      setIsAdminLogingIn(true);
      const result = await sendSignInLinkToEmail(auth, data.email, {
        url: `${domain}/admin/login`,
        handleCodeInApp: true,
      });
      window.localStorage.setItem("emailForSignIn", data.email);
      setAdminLoginResultData(result);
      setIsAdminLogingIn(false);
      const message =
        "An email with link to sign in was sent to your email address.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
      return result;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setAdminLoginResultData(undefined);
      setIsAdminLogingIn(false);
    }
  };

  const checkIsSignInWithEmailLink = useCallback(async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setIsAdminLogingIn(true);
      try {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        if (email) {
          await signInWithEmailLink(auth, email, window.location.href);
          window.localStorage.removeItem("emailForSignIn");
        }
        setIsAdminLogingIn(false);
      } catch (error) {
        const errorData = error as Error;
        const errorId = `error-${errorData.message}`;
        if (!toast.isActive(errorId)) {
          toast({
            id: errorId,
            description: errorData.message,
            status: "error",
            variant: "left-accent",
            position: "top",
          });
        }
        setIsAdminLogingIn(false);
      }
    }
  }, [toast]);

  useEffect(() => {
    checkIsSignInWithEmailLink();
  }, [checkIsSignInWithEmailLink]);

  return {
    isAdminLogingIn,
    adminLoginResultData,
    adminLogin,
  };
};

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowCircleRight2, ArrowDown2 } from "iconsax-react";
import Joi from "joi";
import moment from "moment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useChangeTicketDate } from "../../../adaptors";
import {
  ChangeTicketDateInput,
  OrderData,
  PremiereDateData,
} from "../../../services/firebase";
import ConfirmChangeTicketDateModal from "./ConfirmChangeTicketDateModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  orderData: OrderData;
  premiereDates: PremiereDateData[];
  reloadOrder: () => void;
}

const ChangeTicketDateModal: React.FC<Props> = ({
  isOpen,
  onClose,
  orderData: { movieId, id: orderId, premiereDate: oldPremiereDate },
  premiereDates,
  reloadOrder,
}) => {
  const {
    isOpen: isConfirmChangeTicketDateModalOpen,
    onOpen: onOpenConfirmChangeTicketDateModal,
    onClose: onCloseConfirmChangeTicketDateModal,
  } = useDisclosure();
  const [changeTicketDateReference, setChangeTicketDateReference] =
    useState<string>();
  const defaultValues: Partial<ChangeTicketDateInput> = {};

  const validationSchema = Joi.object<ChangeTicketDateInput>({
    premiereDate: Joi.string()
      .required()
      .valid(...premiereDates.map((value) => value.id))
      .messages({ "any.only": "is not a valid premiere date" }),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangeTicketDateInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });

  const { changeTicketDate, isChangingTicketDate } = useChangeTicketDate();

  const changeTicketDateHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      const data = await changeTicketDate({
        ...input,
        movieId,
        orderId,
      });
      if (data) {
        setChangeTicketDateReference(data.reference);
        onOpenConfirmChangeTicketDateModal();
        onCloseHandler();
      }
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  const renderPremeireDatesOptions = premiereDates.map((premiereDate) => {
    const { id, date } = premiereDate;
    const isDatePassed = moment().isAfter(moment.unix(date.seconds));
    if (isDatePassed || id === oldPremiereDate) {
      return null;
    }
    return (
      <MenuItemOption key={id} value={id}>
        <Wrap>
          <WrapItem>
            <Box>
              <Text fontSize="sm">
                {moment(moment.unix(date.seconds)).format("LLL")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
      </MenuItemOption>
    );
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="md"
          rounded={[0, "md"]}
          containerProps={{
            p: ["0", "5"],
          }}
          height={["auto", "auto"]}
          minHeight={["full", "auto"]}
          marginTop={["0", "3.75rem"]}
          marginBottom={["0", "3.75rem"]}
        >
          <ModalHeader>Change order date</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
            <form onSubmit={changeTicketDateHandler}>
              <VStack align="stretch" spacing="5">
                <Controller
                  control={control}
                  name="premiereDate"
                  render={({ field: { value, onChange, onBlur } }) => {
                    const premiereDate = premiereDates.find(
                      (date) => date.id === value
                    );
                    return (
                      <FormControl isInvalid={!!errors.premiereDate} zIndex={2}>
                        <Menu closeOnSelect matchWidth>
                          <MenuButton
                            as={Box}
                            width="full"
                            textAlign="start"
                            borderWidth={1}
                            rounded={5}
                            px="4"
                            py="2"
                            _hover={{
                              borderColor: "gray.300",
                            }}
                            _focus={{
                              borderColor: "blue.300",
                            }}
                            cursor="pointer"
                          >
                            <HStack
                              align="center"
                              justifyContent="space-between"
                            >
                              {premiereDate ? (
                                <Box>
                                  <Text fontSize="sm" fontWeight="semibold">
                                    {moment(
                                      moment.unix(premiereDate.date.seconds)
                                    ).format("LLL")}
                                  </Text>
                                  <Text fontSize="sm">
                                    {premiereDate.location}
                                  </Text>
                                  <Text fontSize="sm">
                                    {premiereDate.venue}
                                  </Text>
                                </Box>
                              ) : (
                                <Text>{`Select date`}</Text>
                              )}
                              <ArrowDown2 size={16} variant="Bold" />
                            </HStack>
                          </MenuButton>
                          <MenuList minWidth="full" maxWidth="fit-content">
                            <MenuOptionGroup
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              type="radio"
                            >
                              {renderPremeireDatesOptions}
                            </MenuOptionGroup>
                          </MenuList>
                        </Menu>
                        <FormErrorMessage>
                          {!!errors.premiereDate && errors.premiereDate.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              rightIcon={<ArrowCircleRight2 color="white" variant="Bold" />}
              isLoading={isChangingTicketDate}
              onClick={changeTicketDateHandler}
            >
              Continue
            </Button>
            <Button onClick={onCloseHandler}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {changeTicketDateReference && (
        <ConfirmChangeTicketDateModal
          isOpen={isConfirmChangeTicketDateModalOpen}
          onClose={onCloseConfirmChangeTicketDateModal}
          reference={changeTicketDateReference}
          reloadOrder={reloadOrder}
        />
      )}
    </>
  );
};

export default ChangeTicketDateModal;

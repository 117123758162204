import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  SendReminderEmailsInput,
  SendReminderEmailsOutput,
  sendReminderEmailsService,
} from "../services/firebase";

export const useSendReminderEmails = () => {
  const toast = useToast();
  const [isSendingReminderEmails, setIsSendingReminderEmails] = useState(false);
  const [sendReminderEmailsResultData, setSendReminderEmailsResultData] =
    useState<SendReminderEmailsOutput>();

  const sendReminderEmails = async (data: SendReminderEmailsInput) => {
    try {
      setSendReminderEmailsResultData(undefined);
      setIsSendingReminderEmails(true);
      const result = await sendReminderEmailsService(data);
      setSendReminderEmailsResultData(result.data);
      setIsSendingReminderEmails(false);
      const message = `Reminder emails have been scheduled for ${result.data.emails.length} user(s)`;
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setSendReminderEmailsResultData(undefined);
      setIsSendingReminderEmails(false);
    }
  };

  return {
    isSendingReminderEmails,
    sendReminderEmailsResultData,
    sendReminderEmails,
  };
};

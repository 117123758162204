import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  SendInvitationInput,
  SendInvitationOutput,
  sendInvitationService,
} from "../services/firebase";

export const useSendInvitation = () => {
  const toast = useToast();
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [sendInvitationResultData, setSendInvitationResultData] =
    useState<SendInvitationOutput>();

  const sendInvitation = async (data: SendInvitationInput) => {
    try {
      setSendInvitationResultData(undefined);
      setIsSendingInvitation(true);
      const result = await sendInvitationService(data);
      setSendInvitationResultData(result.data);
      setIsSendingInvitation(false);
      const message = "Your recommendation has been sent.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setSendInvitationResultData(undefined);
      setIsSendingInvitation(false);
    }
  };

  return {
    isSendingInvitation,
    sendInvitationResultData,
    sendInvitation,
  };
};

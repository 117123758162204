import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ConfirmChangeTicketDateInput,
  ConfirmChangeTicketDateOutput,
  confirmChangeTicketDateService,
} from "../services/firebase";

export const useConfirmChangeTicketDate = () => {
  const toast = useToast();
  const [isConfirmingChangeTicketDate, setIsConfirmingChangeTicketDate] =
    useState(false);
  const [
    confirmChangeTicketDateResultData,
    setConfirmChangeTicketDateResultData,
  ] = useState<ConfirmChangeTicketDateOutput>();

  const confirmChangeTicketDate = async (
    data: ConfirmChangeTicketDateInput
  ) => {
    try {
      setConfirmChangeTicketDateResultData(undefined);
      setIsConfirmingChangeTicketDate(true);
      const result = await confirmChangeTicketDateService(data);
      setConfirmChangeTicketDateResultData(result.data);
      setIsConfirmingChangeTicketDate(false);
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setConfirmChangeTicketDateResultData(undefined);
      setIsConfirmingChangeTicketDate(false);
    }
  };

  return {
    isConfirmingChangeTicketDate,
    confirmChangeTicketDateResultData,
    confirmChangeTicketDate,
  };
};

import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  AddReviewInput,
  AddReviewOutput,
  addReviewService,
} from "../services/firebase";

export const useAddReview = () => {
  const toast = useToast();
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [addReviewResultData, setAddReviewResultData] =
    useState<AddReviewOutput>();

  const addReview = async (data: AddReviewInput) => {
    try {
      setAddReviewResultData(undefined);
      setIsAddingReview(true);
      const result = await addReviewService(data);
      setAddReviewResultData(result.data);
      setIsAddingReview(false);
      const message = "Your review has been submitted.";
      const successId = `success-${message}`;
      toast({
        id: successId,
        description: message,
        status: "success",
        variant: "left-accent",
        position: "top",
      });
      return result.data;
    } catch (error) {
      const errorData = error as Error;
      const errorId = `error-${errorData.message}`;
      if (!toast.isActive(errorId)) {
        toast({
          id: errorId,
          description: errorData.message,
          status: "error",
          variant: "left-accent",
          position: "top",
        });
      }
      setAddReviewResultData(undefined);
      setIsAddingReview(false);
    }
  };

  return {
    isAddingReview,
    addReviewResultData,
    addReview,
  };
};

import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useSendInvitation } from "../../../adaptors";

interface FormInput {
  emails: string;
}

interface Props {
  isOpen: boolean;
  movieId: string;
  orderId: string;
  onClose: () => void;
}

const validationSchema = Joi.object<FormInput>({
  emails: Joi.string().required(),
});

const extractEmails = (input: string) => {
  return input.match(/[a-z\d._+-]+@[a-z\d._-]+/gi);
};

const InvitationModal: React.FC<Props> = ({
  isOpen,
  movieId,
  orderId,
  onClose,
}) => {
  const { sendInvitation, isSendingInvitation } = useSendInvitation();
  const defaultValues: Partial<FormInput> = {
    emails: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });

  const sendInvitationHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      let emails = extractEmails(input.emails);
      if (emails) {
        emails = emails.filter((item, pos, self) => {
          return self.indexOf(item) === pos;
        });
      }
      if (!emails || emails.length < 1 || emails.length > 10) {
        setError("emails", {
          message: "You can only add 1 to 10 valid email aaddresses",
        });
        return;
      }
      await sendInvitation({
        emails,
        movieId,
        orderId,
      });
      onCloseHandler();
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="md"
        rounded={[0, "md"]}
        containerProps={{
          p: ["0", "5"],
        }}
        height={["auto", "auto"]}
        minHeight={["full", "auto"]}
        marginTop={["0", "3.75rem"]}
        marginBottom={["0", "3.75rem"]}
      >
        <ModalHeader>Recommend movie</ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
          <form onSubmit={sendInvitationHandler}>
            <VStack align="stretch" spacing={2.5}>
              <Text>
                Recommend this movie to others for a chance to win exciting
                prizes.
              </Text>
              <FormControl isInvalid={!!errors.emails}>
                <Textarea
                  placeholder="Enter the email addresses of people you wish to recommend this movie to."
                  {...register("emails")}
                />
                <FormErrorMessage>
                  {!!errors.emails && errors.emails.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={sendInvitationHandler}
            isLoading={isSendingInvitation}
            loadingText="Sending..."
          >
            Send recommendation
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InvitationModal;

import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React from "react";
import { useForm } from "react-hook-form";
import { useAddReview } from "../../../adaptors";

interface FormInput {
  content: string;
}

const validationSchema = Joi.object<FormInput>({
  content: Joi.string().required().max(255),
});

interface Props {
  isOpen: boolean;
  movieId: string;
  orderId: string;
  movieTitle: string;
  onClose: () => void;
}

const ReviewModal: React.FC<Props> = ({
  isOpen,
  movieId,
  orderId,
  movieTitle,
  onClose,
}) => {
  const defaultValues: Partial<FormInput> = {
    content: "",
  };
  const { addReview, isAddingReview } = useAddReview();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });

  const submitReviewHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      await addReview({ ...input, movieId, orderId });
      onCloseHandler();
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseHandler}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="md"
        rounded={[0, "md"]}
        containerProps={{
          p: ["0", "5"],
        }}
        height={["auto", "auto"]}
        minHeight={["full", "auto"]}
        marginTop={["0", "3.75rem"]}
        marginBottom={["0", "3.75rem"]}
      >
        <ModalHeader>Leave a review</ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
          <form onSubmit={submitReviewHandler}>
            <VStack align="stretch" spacing={2.5}>
              <Text>
                {`The cast and crew of `}
                <chakra.span fontWeight="semibold">{movieTitle}</chakra.span>
                {` would love to know what you think of it.`}
              </Text>
              <FormControl isInvalid={!!errors.content}>
                <Textarea
                  placeholder="Enter review here"
                  {...register("content")}
                />
                <FormErrorMessage>
                  {!!errors.content && errors.content.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={submitReviewHandler}
            isLoading={isAddingReview}
            loadingText="Submitting..."
          >
            Submit review
          </Button>
          <Button onClick={onCloseHandler}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReviewModal;

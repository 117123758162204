import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { DefaultUi, Hls, Player } from "@vime/react";
import React, { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  src: string;
  movieId: string;
  poster: string;
  title: string;
  style: React.CSSProperties;
  endingTime: number;
  onClose: () => void;
  onSavedTimeChange: (movieId: string) => void;
  isEndingMessageFocused: boolean;
  isEndingMessageBlurred: boolean;
  renderEndingMessage: ({
    isVisible,
    close,
    pauseVideo,
  }: {
    isVisible: boolean;
    close: () => void;
    pauseVideo: () => void;
  }) => JSX.Element;
}

type PlayerStyle = React.CSSProperties & Record<string, string>;

const VideoPlayer: React.FC<Props> = ({
  src,
  movieId,
  poster,
  title,
  style,
  endingTime,
  isEndingMessageFocused,
  isEndingMessageBlurred,
  onClose,
  onSavedTimeChange,
  renderEndingMessage,
}) => {
  const toast = useToast();
  const playerRef = useRef<HTMLVmPlayerElement | null>(null);
  const [isMuted, setMuted] = useState(true);
  const [mainSrc, setMainSrc] = useState(src);
  const [hasResumed, setHasResumed] = useState(false);
  const {
    isOpen: isEndingMessageOpen,
    onClose: closeEndingMessage,
    onOpen: openEndingMessage,
  } = useDisclosure();
  const [hasUserClosedEndingMessage, setHasUserClosedEndingMessage] =
    useState(false);
  const isComponentActiveRef = useRef(true);
  const [mainPoster, setMainPoster] = useState(poster);
  const [canShowCustomUI, setCanshowCustomUI] = useState(false);
  const isEndingMessageFocusedRef = useRef(isEndingMessageFocused);

  useEffect(() => {
    setMainSrc(src);
  }, [src]);

  useEffect(() => {
    return () => {
      isComponentActiveRef.current = false;
    };
  }, []);

  useEffect(() => {
    isEndingMessageFocusedRef.current = isEndingMessageFocused;
  }, [isEndingMessageFocused]);

  const handleCloseEndingMessage = useCallback(() => {
    closeEndingMessage();
    setHasUserClosedEndingMessage(true);
    if (playerRef.current!.playbackEnded) {
      onClose();
    }
  }, [closeEndingMessage, onClose]);

  useEffect(() => {
    if (isEndingMessageBlurred) {
      if (playerRef.current && playerRef.current.paused) {
        playerRef.current.play();
      }
      handleCloseEndingMessage();
    }
  }, [handleCloseEndingMessage, isEndingMessageBlurred]);

  useEffect(() => {
    setMainPoster(poster);
  }, [poster]);

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      const playerSREle = document.createElement("style");
      playerSREle.innerHTML = ".player.video { display: contents }";
      const shadowRoot = player.shadowRoot;
      if (shadowRoot) {
        shadowRoot.appendChild(playerSREle);
      }
      const UA = window.navigator.userAgent.toLowerCase();
      const IS_IOS = /iphone|ipad|ipod|ios|CriOS|FxiOS/.test(UA);
      setCanshowCustomUI(!IS_IOS);
    }

    const keyboardEventHandler = (event: KeyboardEvent) => {
      if (player && !isEndingMessageFocusedRef.current) {
        switch (event.key) {
          case "k":
          case " ": {
            if (player.paused) {
              player.play();
            } else {
              player.pause();
            }
            break;
          }
          case "m": {
            if (player.muted) {
              player.muted = false;
            } else {
              player.muted = true;
            }
            break;
          }
          case "f": {
            if (player.isFullscreenActive) {
              player.exitFullscreen();
            } else {
              player.enterFullscreen();
            }
            break;
          }
          case "p": {
            if (player.isPiPActive) {
              player.exitPiP();
            } else {
              player.enterPiP();
            }
            break;
          }
        }
      }
      event.stopPropagation();
    };

    if (player) {
      document.addEventListener("keydown", keyboardEventHandler, true);
    }

    return () => {
      if (player) {
        document.removeEventListener("keydown", keyboardEventHandler, true);
      }
    };
  }, [playerRef]);

  const playerStyle: PlayerStyle = {
    "--vm-player-theme": "#38A169",
    "--vm-captions-cue-bg-color": "none",
    "--vm-captions-font-size": "25px",
  };

  const handlePlaybackStarted = () => {
    setMuted(false);
    const player = playerRef.current;
    if (player) {
      const vmDefaultUIEle = player.getElementsByTagName("vm-default-ui")[0];
      if (
        vmDefaultUIEle &&
        vmDefaultUIEle.shadowRoot &&
        vmDefaultUIEle.shadowRoot.firstElementChild
      ) {
        vmDefaultUIEle.shadowRoot.childNodes.forEach((childNode, key) => {
          if (childNode.nodeName.toLowerCase() === "vm-ui") {
            const vmUiEle = vmDefaultUIEle.shadowRoot?.children[key];
            if (vmUiEle) {
              const vmCaptionsEle =
                vmUiEle.getElementsByTagName("vm-captions")[0];

              if (vmCaptionsEle && vmCaptionsEle.shadowRoot) {
                const captionSREle = document.createElement("style");
                captionSREle.innerHTML =
                  ".cue { text-shadow: 1px 1px 3px rgb(0 0 0 / 75%) !important; font-family: arial; font-weight: bold; line-height: 120% !important; }";
                vmCaptionsEle.shadowRoot.appendChild(captionSREle);
              }
            }
          }
        });
      }
    }
  };

  const pauseVideoOnEndingMessageView = () => {
    playerRef.current!.pause();
  };

  const handlePlaybackEnded = () => {
    window.localStorage.setItem(`${movieId}_current_time`, "0");
    onSavedTimeChange(movieId);
    if (!isEndingMessageOpen) {
      onClose();
    }
  };

  const handleFullscreenChange = (event: CustomEvent<boolean>) => {
    if (event.detail) {
      playerRef.current!.isControlsActive = false;
    }
  };

  const handleCurrentTimeChange = (event: CustomEvent<number>) => {
    if (!hasResumed) {
      const savedOldTime = window.localStorage.getItem(
        `${movieId}_current_time`
      );
      const oldTime = Number(savedOldTime);
      const isEndTime =
        Math.floor(oldTime) === Math.floor(playerRef.current!.duration);
      if (oldTime && !isEndTime) {
        playerRef.current!.currentTime = oldTime;
      }
      setHasResumed(true);
    }
    let currentTimeString = event.detail.toString();
    const finalEndingTime = endingTime || playerRef.current!.duration;
    if (Math.floor(event.detail) >= Math.floor(finalEndingTime)) {
      const endingMessageSeen = window.localStorage.getItem(
        `${movieId}_ending_message_seen`
      );
      if (
        !isEndingMessageOpen &&
        !hasUserClosedEndingMessage &&
        endingMessageSeen !== "true"
      ) {
        playerRef.current!.exitFullscreen();
        openEndingMessage();
        window.localStorage.setItem(`${movieId}_ending_message_seen`, "true");
        setTimeout(() => {
          if (isComponentActiveRef.current) {
            handleCloseEndingMessage();
          }
        }, 60000);
      }
      currentTimeString = "0";
    }
    window.localStorage.setItem(`${movieId}_current_time`, currentTimeString);
    onSavedTimeChange(movieId);
  };

  const handleError = (event: CustomEvent<any>) => {
    const eventData = event.detail?.data;
    const errorMessage = `An error occured.. (${eventData.type}, ${eventData.details})`;
    if (eventData.fatal === false) {
      return;
    }
    const errorId = `error-${errorMessage}`;
    if (!toast.isActive(errorId)) {
      toast({
        id: errorId,
        description: errorMessage,
        status: "error",
        variant: "left-accent",
        position: "top",
      });
    }
    onClose();
  };

  return (
    <>
      <Box style={style}>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Box width="full">
            <Player
              ref={playerRef}
              style={playerStyle}
              muted={isMuted}
              autoplay
              onVmPlaybackStarted={handlePlaybackStarted}
              onVmError={handleError}
              onVmPlaybackEnded={handlePlaybackEnded}
              onVmCurrentTimeChange={handleCurrentTimeChange}
              mediaTitle={title}
              onVmFullscreenChange={handleFullscreenChange}
              controls={!canShowCustomUI}
            >
              <Hls
                version="latest"
                poster={mainPoster}
                config={{
                  maxMaxBufferLength: 30,
                }}
              >
                <source data-src={mainSrc} type="application/x-mpegURL" />
              </Hls>
              {canShowCustomUI && <DefaultUi noClickToPlay></DefaultUi>}
              {renderEndingMessage({
                isVisible: isEndingMessageOpen,
                close: handleCloseEndingMessage,
                pauseVideo: pauseVideoOnEndingMessageView,
              })}
            </Player>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default VideoPlayer;

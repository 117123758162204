import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
  useToken,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  ArrowCircleRight2,
  ArrowDown2,
  Profile,
  Profile2User,
} from "iconsax-react";
import Joi from "joi";
import moment from "moment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInitializeTransaction } from "../../../adaptors";
import {
  ageRanges,
  BookingType,
  InitializeTransactionInput,
  MovieData,
  PremiereDateData,
} from "../../../services/firebase";
import ConfirmBookingModal from "./ConfirmBookingModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  movieData: MovieData;
  premiereDates: PremiereDateData[];
}

const BookOrderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  movieData,
  premiereDates,
}) => {
  const [green500] = useToken("colors", ["green.500"]);
  const {
    isOpen: isConfirmBookingModalOpen,
    onOpen: onOpenConfirmBookingModal,
    onClose: onCloseConfirmBookingModal,
  } = useDisclosure();
  const [bookingReference, setBookingReference] = useState<string>();
  const defaultValues: Partial<InitializeTransactionInput> = {
    seats: 1,
  };

  const validationSchema = Joi.object<InitializeTransactionInput>({
    premiereDate: Joi.string()
      .required()
      .valid(...premiereDates.map((value) => value.id))
      .messages({ "any.only": "is not a valid premiere date" }),
    seats: Joi.number()
      .required()
      .integer()
      .min(1)
      .max(movieData.maxSeatsPerTicket),
    fullname: Joi.string().required().trim().min(3),
    ageRange: Joi.string()
      .required()
      .valid(...ageRanges)
      .messages({ "any.only": "is not a valid age range" }),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } }),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<InitializeTransactionInput>({
    resolver: joiResolver(validationSchema, {
      errors: {
        label: false,
      },
    }),
    defaultValues,
  });
  const watchForm = watch();
  const { initializeTransaction, isInitializingTransaction } =
    useInitializeTransaction();

  const initializeTransactionHandler = () => {
    const onSubmit = handleSubmit(async (input) => {
      const data = await initializeTransaction({
        ...input,
        movieId: movieData.id,
        type: BookingType.Ticket,
      });
      if (data) {
        setBookingReference(data.reference);
        onOpenConfirmBookingModal();
        onCloseHandler();
      }
    });
    onSubmit();
  };

  const onCloseHandler = () => {
    onClose();
    reset(defaultValues);
  };

  const renderPremeireDatesOptions = premiereDates.map((premiereDate) => {
    const { id, date } = premiereDate;
    return (
      <MenuItemOption key={id} value={id}>
        <Wrap>
          <WrapItem>
            <Box>
              <Text fontSize="sm">
                {moment(moment.unix(date.seconds)).format("LLL")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
      </MenuItemOption>
    );
  });

  const renderAgeRangeOptions = ageRanges.map((ageRange) => (
    <option key={ageRange} value={ageRange}>{`${ageRange} years old`}</option>
  ));

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="md"
          rounded={[0, "md"]}
          containerProps={{
            p: ["0", "5"],
          }}
          height={["auto", "auto"]}
          minHeight={["full", "auto"]}
          marginTop={["0", "3.75rem"]}
          marginBottom={["0", "3.75rem"]}
        >
          <ModalHeader>Book order</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderTopWidth={1} borderBottomWidth={1} py="5">
            <form onSubmit={initializeTransactionHandler}>
              <VStack align="stretch" spacing="5">
                <Controller
                  control={control}
                  name="premiereDate"
                  render={({ field: { value, onChange, onBlur } }) => {
                    const premiereDate = premiereDates.find(
                      (date) => date.id === value
                    );
                    return (
                      <FormControl isInvalid={!!errors.premiereDate} zIndex={2}>
                        <Menu closeOnSelect matchWidth>
                          <MenuButton
                            as={Box}
                            width="full"
                            textAlign="start"
                            borderWidth={1}
                            rounded={5}
                            px="4"
                            py="2"
                            _hover={{
                              borderColor: "gray.300",
                            }}
                            _focus={{
                              borderColor: "blue.300",
                            }}
                            cursor="pointer"
                          >
                            <HStack
                              align="center"
                              justifyContent="space-between"
                            >
                              {premiereDate ? (
                                <Box>
                                  <Text fontSize="sm" fontWeight="semibold">
                                    {moment(
                                      moment.unix(premiereDate.date.seconds)
                                    ).format("LLL")}
                                  </Text>
                                  <Text fontSize="sm">
                                    {premiereDate.location}
                                  </Text>
                                  <Text fontSize="sm">
                                    {premiereDate.venue}
                                  </Text>
                                </Box>
                              ) : (
                                <Text>{`Select date`}</Text>
                              )}
                              <ArrowDown2 size={16} variant="Bold" />
                            </HStack>
                          </MenuButton>
                          <MenuList minWidth="full" maxWidth="fit-content">
                            <MenuOptionGroup
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              type="radio"
                            >
                              {renderPremeireDatesOptions}
                            </MenuOptionGroup>
                          </MenuList>
                        </Menu>
                        <FormErrorMessage>
                          {!!errors.premiereDate && errors.premiereDate.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />

                <Controller
                  name="seats"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl
                      isInvalid={!!errors.seats}
                      borderWidth={1}
                      borderRadius="md"
                      px="5"
                      py="3"
                    >
                      <FormLabel htmlFor="seats">
                        <chakra.span fontWeight="bold">{`${value} `}</chakra.span>
                        {`Seat${value > 1 ? "s (Couple)" : " (Single)"}`}
                      </FormLabel>
                      <Slider
                        id="seats"
                        min={1}
                        max={movieData.maxSeatsPerTicket}
                        step={1}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        <SliderTrack bg="green.100">
                          <Box position="relative" right={10} />
                          <SliderFilledTrack bg="green.500" />
                        </SliderTrack>
                        <SliderThumb boxSize={6}>
                          {value === 1 ? (
                            <Profile
                              size="16"
                              variant="Bold"
                              color={green500}
                            />
                          ) : (
                            <Profile2User
                              size="16"
                              variant="Bold"
                              color={green500}
                            />
                          )}
                        </SliderThumb>
                      </Slider>
                      <FormErrorMessage>
                        {!!errors.seats && errors.seats.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <FormControl isInvalid={!!errors.fullname}>
                  <Input placeholder="Fullname" {...register("fullname")} />
                  <FormErrorMessage>
                    {!!errors.fullname && errors.fullname.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.ageRange}>
                  <Select placeholder="Age range" {...register("ageRange")}>
                    {renderAgeRangeOptions}
                  </Select>
                  <FormErrorMessage>
                    {!!errors.ageRange && errors.ageRange.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.email}>
                  <Input
                    type="email"
                    placeholder="Email address"
                    {...register("email")}
                  />
                  <FormErrorMessage>
                    {!!errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                <Text textAlign="right">
                  {`Total cost: `}
                  <chakra.span fontWeight="bold">
                    {(
                      movieData.ticketPrice * watchForm.seats -
                      (watchForm.seats > 1
                        ? movieData.discount * watchForm.seats
                        : 0)
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })}
                  </chakra.span>
                </Text>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              rightIcon={<ArrowCircleRight2 color="white" variant="Bold" />}
              isLoading={isInitializingTransaction}
              onClick={initializeTransactionHandler}
            >
              Continue
            </Button>
            <Button onClick={onCloseHandler}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {bookingReference && (
        <ConfirmBookingModal
          isOpen={isConfirmBookingModalOpen}
          onClose={onCloseConfirmBookingModal}
          reference={bookingReference}
        />
      )}
    </>
  );
};

export default BookOrderModal;
